import styled from "styled-components";
import Colors from "./Colors";

export const ActeurImage = styled.div`
  width: 200px;
  height: 200px;
  background-image: ${({ backgroundImage }) => `${backgroundImage}`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5%;
`;

export const Ul = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Deux éléments par ligne */
  gap: 20px; /* Réduire l'espacement entre les éléments */
  list-style-type: none; /* Supprimer les points des listes */
  padding: 0; /* Supprimer le padding par défaut de ul */
  margin: 0; /* Supprimer la marge par défaut de ul */
`;

export const Li = styled.li`
  position: relative;
  color: ${Colors.color1};
  font-size: 1rem;
  justify-self: start; /* Aligne les éléments à gauche */

  /* changer la couleur du texte au survol */
  &:hover a {
    color: ${Colors.color1};
  }

  /* changer la couleur des liens visités */
  a:visited {
    color: ${Colors.color1};
  }

  /* changer la couleur des liens non visités */
  a:link {
    color: ${Colors.color1};
  }
`;

export const TooltipContent = styled.div`
  position: absolute; /* Assurez-vous que le tooltip est positionné absolument par rapport à son conteneur */
  top: -10px; /* Ajustez la position du tooltip au-dessus de l'image */
  left: 50%; /* Centrez le tooltip horizontalement */
  transform: translateX(-50%); /* Centrez le tooltip horizontalement */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px; /* Réduit l'espace de padding si nécessaire */
  width: 200px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 12px; /* Augmentez la taille de la police pour plus de lisibilité */
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 1000; /* Assurez-vous que le z-index est plus élevé que celui de l'image */
  color: black;
`;


export const mainStyles = {
  display: "flex",
  flexDirection: "column", // Pour aligner les éléments verticalement
  alignItems: "flex-start", // Pour centrer horizontalement
  marginTop: "100px",
};