import styled from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  
`;

export const EventGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 15px;

  @media (max-width: 2189px) {
    gap: 20px 100px ; 
  }
  @media (max-width: 1608px) {
    gap: 25px;
  }
  @media (max-width: 1075px ) {
    gap: 10px;
  }

  
`;

export const EventCardContainer = styled.div`
  width: 400px;

  @media (min-width: 2189px) {
    height: auto;
    width: auto;;
  }
  @media (max-width: 2189px) {
    gap: 20px 100px ;
    width : auto;
  }
  @media (max-width: 1608px) {
    gap: 5px;
    width: auto;
  }
  @media (max-width: 990px) {
    width: auto;
    
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size : 16px;

`;

export const Input = styled.input`
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  @media (max-width: 1075px) {
    font-size : 10px;
    width: 100%;
  }
`;
export const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width : 100%;
  margin-top:20px;
  background-color: white;
  color: gray;
  @media (max-width: 1075px) {
    font-size : 10px;
  }
`;