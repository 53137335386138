import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, CloseButton, PopupContent,
  ConfirmButton, ResetButton, ButtonContainer, Input,
  Textarea, Label, InputSection } from "../styles/EditEventPopup.style";


const EditVideoPopup = ({ video, onSave, onClose }) => {
  
  const [id, setId] = useState(video.id);
  const [title, setTitle] = useState(video.title);
  const [url, setUrl] = useState(video.url);

  const handleResetChanges = () => {
    // Reset all the fields here
    setTitle(video.title);
    setUrl(video.url);
  };

  const handleSaveChanges = () => {
    const createdVideo = {
        id: id,
      title: title,
      url:url,
    };
    onSave(createdVideo);
  };


  return (

    <PopupContainer>
      <PopupContent>

        <Title>Modifier un vidéo</Title>

        <CloseButton onClick={onClose} data-testid="close-button">
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          <Label>Titre:</Label>
          <Input
            type="text"
            placeholder="Titre de la vidéo"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </InputSection>

        <InputSection>
          <Label>Url de la vidéo:</Label>
          <Textarea
            rows="5"
            placeholder="Lien url vers la vidéo"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
        </InputSection>

        <ButtonContainer>
          <ResetButton onClick={handleResetChanges} data-testid="reset-button">Réinitialiser</ResetButton>
          <ConfirmButton onClick={handleSaveChanges} data-testid="confirm-button">Confirmer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
    </PopupContainer>

  );
  
}

export default EditVideoPopup;
