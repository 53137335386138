import React, { useState, useEffect } from 'react';
import { faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import { CardContainer, InfoContainer, Icon, Infos, 
    InfoRow, Name, PageLink, NameUnderCard 
} from '../styles/CartePerso.style';


const defaultImage = 'default_user.png';


const CartePerso = ({ id, name, email, phone, address }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [chercheur, setChercheur] = useState([]);

    useEffect(() => {
        axios
            .get(`https://back.r3mob.fr/detailsChercheur/image/${id}`)
            .then((res) => {
                if (res.data !== null) {
                    setChercheur(res.data);
                } else {
                    //window.location.replace('/404');
                }
            }).catch((error) => {
                console.error(error);
              });
    }, [id]);

    const backgroundImage = chercheur.imageData
    ? `url(data:image/png;base64,${chercheur.imageData})`
      : `url(${defaultImage})`;
    
    
    return (
        <>
            <PageLink to={`/chercheur/${id}`}> 

                <CardContainer data-testid="card-container"
                    style={{ backgroundImage }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >

                    {isHovered && (
                        <InfoContainer>

                            <Name data-testid="name">{name}</Name>

                            <InfoRow>
                                <Icon>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </Icon>
                                <Infos>{email}</Infos>
                            </InfoRow>
                            
                            <InfoRow>
                                <Icon>
                                    <FontAwesomeIcon icon={faMapMarker} />
                                </Icon>
                                <Infos>{address}</Infos>
                            </InfoRow>

                        </InfoContainer>
                    )}
                </CardContainer>
            </PageLink>
            
            <NameUnderCard data-testid="name-under-card">{name}</NameUnderCard>
        </>
    );
};

export default CartePerso;
