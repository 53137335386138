import React, { useContext } from "react";

import { AuthContext } from "../helpers/AuthContext";
import { ButtonContainer, CarteBttn, CarteLink } from "../styles/CarteButton.style";


function CarteButton() {

  const { authState } = useContext(AuthContext);

  return (
      authState.status && (

          <ButtonContainer>
            <CarteBttn to="/carte" target="_blank" rel="noopener noreferrer"/>
          </ButtonContainer>

      )
  );
}

export default CarteButton;
