import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { FaEnvelope, FaBuilding} from "react-icons/fa";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import Thematiques from "../components/Thematiques";
import ThematiqueIcon from "../components/ThematiqueIcon";
import CartePublication from "../components/CartePublication";
import ChangerPhotoPopup from "../components/ChangerPhotoPopup";

import Colors from "../styles/Colors";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { RightSide, HeaderContainer, Infos, ChercheurEtabs,
  Etab, ChercheurName, ChercheurImage, SimilarEventsTitle, ThematiquesContainer,
  PublicationGrid, PublicationCardContainer, Icon } from "../styles/ChercheurDetails.style";
import { ButtonContainer, Button } from "../styles/Profile.style";


function Profile() {

  const { authState } = useContext(AuthContext);
  const chercheurId = authState.id;
  const [chercheur, setChercheur] = useState({});
  const [etablissement, setEtablissement] = useState([]);
  const [publications, setPublications] = useState([]);
  const [allThematique, setAllThematique] = useState([]);
  const [extractedThematiques, setExtractedThematiques] = useState([]);
  const [chercheurSousThematiques, setChercheurSousThematiques] = useState([]);
  const [showChangerPhotoPopup, setShowChangerPhotoPopup] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(false);

  useEffect(() => {
    axios
      .get(`https://back.r3mob.fr/detailsChercheur/${chercheurId}`)
      .then((res) => {
        if (res.data !== null) {
          setChercheur(res.data);
          setChercheurSousThematiques(res.data.Thematique_chercheurs);
        } else {
          window.location.replace("/404");
        }
      });
    axios
      .get(`https://back.r3mob.fr/etablissement/chercheur/${chercheurId}`)
      .then((res) => {
        if (res.data !== null) {
          setEtablissement(res.data);
        }
      });
    axios
      .get(`https://back.r3mob.fr/publication/personnel/${chercheurId}`)
      .then((res) => {
        if (res.data !== null) {
          setPublications(res.data);
        }
      });
  }, [chercheurId, shouldReloadPage]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      if (res.data.error) {
        console.log(res.data.error);
      } else {
        setAllThematique(res.data);
      }
    });
  }, []);

  useEffect(() => {
    // Define an async function to fetch the thematic data
    const fetchThematiqueData = async () => {
      const thematicData = [];
      for (const publication of chercheurSousThematiques) {
        const thematicId = publication.SousThematique
          ? publication.SousThematique.ThematiqueId
          : null;
        if (!thematicId) continue;
        const thematic = allThematique.find(
          (thematique) => thematique.id === thematicId
        );
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex(
            (data) => data.thematique.nom === thematic.nom
          );
          if (thematiqueIndex !== -1) {
            // If the thematique already exists, update the sousThematique field
            thematicData[thematiqueIndex].sousThematique.push(
              " , ",
              publication.SousThematique.nom
            );
          } else {
            // If the thematique doesn't exist, add it to the array
            const them = Thematiques.find(
              (thematique) => thematique.nom === thematic.nom
            );
            thematicData.push({
              thematique: them,
              sousThematique: [publication.SousThematique.nom],
            });
          }
        }
      }
      //Extraire les Thematiques
      for (const publication of chercheurSousThematiques) {
        const thematicId = publication.ThematiqueId
          ? publication.ThematiqueId
          : null;
        if (!thematicId) continue;
        const thematic = allThematique.find(
          (thematique) => thematique.id === thematicId
        );
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex(
            (data) => data.thematique.nom === thematic.nom
          );
          // If the thematique doesn't exist, add it to the array
          if (thematiqueIndex === -1) {
            const them = Thematiques.find(
              (thematique) => thematique.nom === thematic.nom
            );
            thematicData.push({ thematique: them, sousThematique: [them.nom] });
          }
        }
      }
      setExtractedThematiques(thematicData);
    };
    fetchThematiqueData();
  }, [chercheurSousThematiques, allThematique]);

  const formattedDate = new Date(chercheur.startDateTime).toLocaleDateString(
    "fr-FR",
    {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  const reload = () => {
    setShouldReloadPage(!shouldReloadPage);
  };

  const defaultImage = "../default_user.png";
  const backgroundImage = chercheur.imageData
    ? `url(data:image/png;base64,${chercheur.imageData})`
    : `url(${defaultImage})`;


  return ( authState.status == true ? (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "} Profile - {chercheur.username}</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">

        <main>

          <HeaderContainer>

            <ChercheurImage backgroundImage={backgroundImage} />

            <Infos>
              
              {chercheur.isActive === false && (
                <Etab
                  style={{
                    color: Colors.color2,
                    marginTop: "5%",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  #Acteur Inactif
                </Etab>
              )}
              
              <ChercheurName>{chercheur.username}</ChercheurName>
              
              <ChercheurEtabs>
                <Icon>
                  <FaEnvelope />
                </Icon>
                <Etab>
                    {chercheur.email}
                </Etab>
              </ChercheurEtabs>

                <ChercheurEtabs>
                  <Icon>
                    <FaBuilding />
                  </Icon>
                  {etablissement.map((etab, index) => {
                    return (
                      <Etab key={etab.EtablissementId}>
                        {etab.Etablissement.nom}
                        {index < etablissement.length - 1 ? ", " : ""}
                      </Etab> 
                    );
                  })}
                </ChercheurEtabs>

            </Infos>

            <RightSide>

              <ThematiquesContainer>
                {extractedThematiques.map((publicationSousThematiques) => (
                  <ThematiqueIcon
                    key={publicationSousThematiques.thematique.nom}
                    icon={
                      "../thematiques/" +
                      publicationSousThematiques.thematique.icon
                    }
                    backgroundColor={
                      publicationSousThematiques.thematique.backgroundColor
                    }
                    subThematiques={publicationSousThematiques.sousThematique}
                  />
                ))}
              </ThematiquesContainer>

              <p>Nombre de publications liées à R3MOB: {publications.length}{" "}</p>
            
            </RightSide>

          </HeaderContainer>

          <ButtonContainer>
            <Button onClick={() => {setShowChangerPhotoPopup(true);}}>
              Changer photo de profile
            </Button>
            <Button onClick={() => {window.location.replace("/changePassword");}}>
              Changer votre mot de passe
            </Button>
          </ButtonContainer>
          
          <SimilarEventsTitle>

            {publications.length != 0 ? (
              <>
                <p>Les Publications de {chercheur.username}</p>
                <PublicationGrid>
                  {publications.map((value, key) => {
                    return (
                      <PublicationCardContainer key={key}>
                        <CartePublication
                          id={value.id}
                          url={value.url}
                          title={value.nom}
                          imageUrl={value.imageName}
                          fallbackUrl="../mob.jpg"
                          idChercheur={value.chercheurs[0]}
                          reload={reload}
                        />
                      </PublicationCardContainer>
                    );
                  })}
                </PublicationGrid>
              </>
            ) : (
              <p>Aucune publication pour {chercheur.username}.</p>
            )}

          </SimilarEventsTitle>
          
        </main>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

      {showChangerPhotoPopup && (
        <ChangerPhotoPopup
          onClose={() => setShowChangerPhotoPopup(false)}
          onSave={async (formData) => {
            setShowChangerPhotoPopup(false);
            try {
              const response = await axios.put(`https://back.r3mob.fr/perso/changeImage/${chercheurId}`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              if(response.data.error){
                alert(response.data.error);
              }
            } catch (error) {
              console.error(error);
              // Gérez les erreurs ici
            }
            reload();
          }}
          chercheurId={chercheurId}
          reload={reload}
        />
      )}
      
    </div>

  ) : (
    <Navigate to="/login" />
  ));

}

export default Profile;
