import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { Table, Th, Td, TableContainer,
  Validate, ConfirmButton} from "../styles/UpdateEvent.style";


function UpdateActeurCounts() {

  const [counts, setCounts] = useState({
    Acteur_R3MOB: 0,
    Laboratoire_Scientifique: 0,
    Universite: 0,
    Autre_Etablissement: 0,
    Partenaire: 0
  });

  const [newCounts, setNewCounts] = useState({
    Acteur_R3MOB: "",
    Laboratoire_Scientifique: "",
    Universite: "",
    Autre_Etablissement: "",
    Partenaire: ""
  });

  useEffect(() => {
    axios.get('https://back.r3mob.fr/annuaire/acteurCount').then((response) => {
      setCounts(response.data);
    });
  }, []);

  const handleUpdateSingleCount = (field) => {
    axios.put(`https://back.r3mob.fr/annuaire/acteurCount/${field}`, {
      count: newCounts[field]
    }).then((response) => {
      // Mettre à jour l'état avec la nouvelle valeur
      setCounts({ ...counts, [field]: response.data.count });
    });
  };

  const handleUpdateAllCounts = () => {
    axios.put('https://back.r3mob.fr/annuaire/acteurCount', newCounts).then((response) => {
      // Mettre à jour l'état avec les nouvelles valeurs
      setCounts(response.data);
    });
  };


  return (

    <TableContainer>

      <Table>

        <thead>
          <tr>
            <Th >Nom du champ</Th>
            <Th >Nombre d'acteur</Th>
            <Th >Actions</Th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(counts).map((field) => (
            <tr key={field}>
              <Td >{field}</Td>
              <Td >{counts[field]}</Td>
              <Td style={{ border: '1px solid #ccc',display : "flex",gap:"10px",justifyContent:"center" }}>
                <input
                  type="text"
                  placeholder={`Nouveau nombre de ${field}`}
                  value={newCounts[field]}
                  onChange={(e) => setNewCounts({ ...newCounts, [field]: e.target.value })}
                />
                <Validate onClick={() => handleUpdateSingleCount(field)}><FontAwesomeIcon icon={faCheck} /></Validate>
              </Td>
            </tr>
          ))}
        </tbody>

      </Table>

      <ConfirmButton
        onClick={handleUpdateAllCounts}
        style={{marginTop: "20px", marginBottom: "20px"}}
      >
        Valider toutes les modifications
      </ConfirmButton>

    </TableContainer>

  );

}

export default UpdateActeurCounts;
