import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import "../styles/Login.css";
import { Body, MainStyle, Label, Input, Button,LinksContainer, mainStyles,
  RechercheEmailButton, RechercheEmailButtonSignup, ResetPasswordButton } from "../styles/Login.style";

import { AuthContext } from "../helpers/AuthContext";
import FindMailPopup from "../components/FindMailPopup";
import SendEmailConfirmationPopup from "../components/SendEmailConfirmationPopup";
import ResetPasswordPopup from "../components/ResetPasswordPopup";

function Login() {

  const [signupUsername, setSignupUsername] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);
  const { id } = useParams();
  const [showFindMail, setShowFindMail] = useState(false);
  const [showConfirmationSendPupup, setShowConfirmationSendPupup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  let Navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axios.get(`https://back.r3mob.fr/perso/email/${id}`).then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          // Set the username to the email obtained from the API response
          setUsername(response.data.email); // Change this line
        }
      });
    }
  }, [id]); // Make sure to include id in the dependency array

  const handleShowMailPopupClose = () => {
    setShowFindMail(false);
  };

  const handleSignupClick = () => {
    axios
      .post("https://back.r3mob.fr/auth/register", { email: signupUsername.trim() })
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          setShowConfirmationSendPupup(true);
        }
      });
  };

  const login = () => {
    const data = { email: username.trim(), password: password };
    axios.post("https://back.r3mob.fr/auth/login", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        localStorage.setItem("accessToken", response.data.token);
        setAuthState({
          username: response.data.username,
          id: response.data.id,
          status: true,
        });
        Navigate("/");
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission behavior
      if (signupUsername) {
        handleSignupClick();
      } else {
        login();
      }
    }
  };

  return (
    <Body>

      <div className="main">

        <main style={mainStyles}>

          <MainStyle>

            <input type="checkbox" id="chk" aria-hidden="true" />

            <div class="signup">
              <Label for="chk" aria-hidden="true">
                Inscription
              </Label>
              <Input
                type="email"
                name="email"
                placeholder="Votre adresse mail..."
                required=""
                value={signupUsername}
                onChange={(event) => {
                  setSignupUsername(event.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <Button onClick={handleSignupClick}>S'inscrire</Button>
              <LinksContainer>
                <RechercheEmailButtonSignup
                  onClick={() => {
                    setShowFindMail(true);
                  }}
                >
                  Trouver votre email.
                </RechercheEmailButtonSignup>
              </LinksContainer>
            </div>

            <div class="login">
              <Label for="chk" aria-hidden="true">
                Connexion
              </Label>
              <div>
                <Input
                  type="text"
                  placeholder="Votre adresse mail..."
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
                <Input
                  type="password"
                  placeholder="Mot de passe..."
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <Button onClick={login}>Se connecter</Button>
                <LinksContainer>
                  <RechercheEmailButton
                    onClick={() => {
                      setShowFindMail(true);
                    }}
                  >
                    Trouver votre email.
                  </RechercheEmailButton>
                  <ResetPasswordButton
                  onClick={() => {
                    setShowResetPasswordPopup(true);
                  }}
                  >
                    Mot de passe oublié?
                  </ResetPasswordButton>
                </LinksContainer>
              </div>
            </div>

          </MainStyle>

        </main>
        
      </div>

      {showFindMail && <FindMailPopup onClose={handleShowMailPopupClose} />}
      {showConfirmationSendPupup && (
        <SendEmailConfirmationPopup
          onClose={() => setShowConfirmationSendPupup(false)}
        />
      )}
      {showResetPasswordPopup && (
        <ResetPasswordPopup
          onClose={() => setShowResetPasswordPopup(false)}
          onSave={() => setShowResetPasswordPopup(false)}
        />
      )}

    </Body>
    
  );
}

export default Login;
