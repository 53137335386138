import styled from "styled-components";
import Colors from "./Colors";

export const Body = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-family: sans-serif;
  content-align: center;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: linear-gradient(to bottom, ${Colors.color1} 40%, ${Colors.color2} ); /*#e8431e , #302b63 70%*/
`;

export const MainStyle = styled.div`
  width: 400px;
  height: 500px;
  background: red;
  overflow: hidden;
  background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38")
    no-repeat center/ cover;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
  margin: 0 auto;
`;

export const Label = styled.label`
  color: #fff;
  font-size: 2.3em;
  justify-content: center;
  display: flex;
  margin-top: 60px;
  margin-bottom: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
`;

export const Input = styled.input`
  width: 60%;
  height: 20px;
  background: ${Colors.color6};
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 20px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  &:focus {
  outline: 2px solid ${Colors.color2}; /* Changez la couleur à ce que vous préférez */
}
`;

export const Button = styled.button`
  width: 60%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: ${Colors.color3};
  background: ${Colors.color2};
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;

  &:hover {
    background: ${Colors.color6};
    color: ${Colors.color2};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
`;

export const RechercheEmailButton = styled.button`
  color: gray;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  height: fit-content;
`;
export const RechercheEmailButtonSignup = styled.button`
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;

`;
export const ResetPasswordButton = styled.button`
  color: gray;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
`;

export const mainStyles = {
  marginTop: "10%",
};