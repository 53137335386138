import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

import { AuthContext } from "../helpers/AuthContext";
import CartePublication from "../components/CartePublication";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Thematiques from "../components/Thematiques";
import CreatePubPopup from "../components/CreatePubPopup";
import Footer from "../components/Footer";
import Defiler from "../components/Defiler";

import "../styles/Commun.css";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { SelectOption, StyledSelect, InputSection, Input, StyledSearchIcon,
  HorizontalLine, ReinitialiserButton, FilterTitle, FilterStyle,
  CheckboxContainer, CheckboxLabel, CheckboxInput, SousThematiqueGroup,
  Label, Horizontal } from "../styles/Filter.style";
import { PublicationCardContainer, PublicationGrid, Button, CreateButtonContainer,
  VideosContainer, VideoContainer } from "../styles/Ressources.style";


function Ressources() {

  const { authState } = useContext(AuthContext);
  const [videos, setVideos] = useState([]);
  const [publications, setPublications] = useState([]);
  const [allSousThematiques, setAllSousThematiques] = useState([]);
  const [allThematiques, setAllThematiques] = useState([]);

  const [publicationTitleFilter, setPublicationTitleFilter] = useState("");
  const [ResearcherFilter, setResearcherFilter] = useState("");
  const [thematiqueFilter, setThematiqueFilter] = useState("");
  const [sousThematiqueFilter, setSousThematiqueFilter] = useState([]);
  const [sousThematiquesSelectFilter, setSousThematiquesSelectFilter] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(false);
  

  const currentHash = window.location.hash;
  
  useEffect(() => {
    if (currentHash === "#videos") {
      // Si l'ancre est "#videos", faites défiler jusqu'à la section "videos"
      const videosSection = document.getElementById("videos");
      if (videosSection) {
        videosSection.scrollIntoView({ behavior: "smooth" });
      }
    } else if (currentHash === "#publications") {
      // Si l'ancre est "#publications", faites défiler jusqu'à la section "publications"
      const publicationsSection = document.getElementById("publications");
      if (publicationsSection) {
        publicationsSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentHash]);



  useEffect(() => {
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      setAllThematiques(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/sousThematique`).then((res) => {
      setAllSousThematiques(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/publication`).then((res) => {
      setPublications(res.data);
    });
  }, [shouldReloadPage]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/videos`).then((res) => {
      setVideos(res.data);
    });
  }, [videos]);

  useEffect(() => {
    console.log("Thematique Filter Changed:", thematiqueFilter);
    console.log("Sous Thematique Filter Changed:", sousThematiqueFilter);
    console.log("Filtered Publications After Change:", filteredPublications);
  }, [thematiqueFilter, sousThematiqueFilter, publications]);
  



  const filteredPublications = publications
    .filter((publication) => {
      const publicationTitleMatches = publication.nom.trim()
        .toLowerCase()
        .includes(publicationTitleFilter.trim().toLowerCase());
      return publicationTitleMatches;
    })
    .filter((publication) =>
      publication.chercheurs.some((chercheur) => {
        const chercheurNames = chercheur.split(" "); 
        const filterValue = ResearcherFilter.trim().toLowerCase();
        return chercheurNames.some((part) =>
          part.trim().toLowerCase().startsWith(filterValue)
        );
      })
    )
    .filter((publication) => {
      if (thematiqueFilter === "") {
        return true; 
      }
      return publication.Thematique_publications.some((thematiquePublication) => {
          const sousThematiqueNom = thematiquePublication.SousThematique?.Thematique?.nom || "";
          const thematiqueNom = thematiquePublication.Thematique?.nom || "";
          return sousThematiqueNom.toLowerCase() === thematiqueFilter.toLowerCase() || thematiqueNom.toLowerCase() === thematiqueFilter.toLowerCase();
        }
      );
    })
    .filter((publication) => {
      if (sousThematiqueFilter.length === 0) {
        return true;
      }
      return publication.Thematique_publications.some((thematiqueProjet) => {
        const sousThematiqueNom = thematiqueProjet.SousThematique?.nom || "";
        return sousThematiqueFilter.includes(sousThematiqueNom.toLowerCase());
      });
    });

  const eventsPerPage = 6;
  const totalPages = Math.ceil(filteredPublications.length / eventsPerPage);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredPublications.slice(indexOfFirstEvent, indexOfLastEvent);

  // Fonction pour aller à la page choisie
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const handleCloseCreatePopup = () => {
    setShowCreatePopup(false);
  };

  const handleCreateCustomPublication = async (createdEvent) => {
    setShowCreatePopup(false);
    try {
      setShouldReloadPage(!shouldReloadPage);
    } catch (error) {
      console.error(error);
    }
  };

  const reload = () => {
    setShouldReloadPage(!shouldReloadPage);
  };

  /* Fonctions pour gérer les changements de filtres */
  const handlePublicationTitleFilterChange = (title) => {
    setPublicationTitleFilter(title.target.value);
    setCurrentPage(1);
  };

  const handleResearcherNameFilterChange = (chercheur) => {
    setResearcherFilter(chercheur.target.value);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setPublicationTitleFilter("");
    setResearcherFilter("");
    setThematiqueFilter("");
    setSousThematiqueFilter([]); 
    setSousThematiquesSelectFilter([]);
    setCurrentPage(1);
  };
  

  const handleThematiqueFilterChange = (selectedThematique) => {
    setThematiqueFilter(selectedThematique);
    const sousThematiquesFiltrees = allSousThematiques.filter(
      (sousThematique) => {
        return sousThematique.Thematique.nom === selectedThematique;
      }
    );
    setSousThematiquesSelectFilter(sousThematiquesFiltrees);
    setCurrentPage(1);
  };

  const handleSousThematiqueFilterChange = (selectedSousThematique) => {
    if (sousThematiqueFilter.includes(selectedSousThematique)) {
      setSousThematiqueFilter(
        sousThematiqueFilter.filter(
          (sousThematique) => sousThematique !== selectedSousThematique
        )
      );
    } else {
      setSousThematiqueFilter([...sousThematiqueFilter, selectedSousThematique]);
    }
    setCurrentPage(1);
  };
  


  return (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "} Ressources</HeaderLinkStyle>
      </HeaderContent>
      </header>
      
      <div className="main">
        
        <div className="publications section">
          
          <main>

            {authState.status && (
              <CreateButtonContainer>
                <Button onClick={() =>{setShowCreatePopup(true)}}>
                  Ajouter une publication
                </Button>
              </CreateButtonContainer>
            )}

            <h1 className="mainTitle" id="publications">Publications</h1>

            {currentEvents.length != 0 ? (

              <PublicationGrid>
                {currentEvents.map((value, key) => {
                  return (
                    <PublicationCardContainer key={key}>
                      <CartePublication
                        id={value.id}
                        url={value.url}
                        title={value.nom}
                        imageUrl={value.imageName}
                        fallbackUrl="mob.jpg"
                        reload={reload}
                        idChercheur={value.chercheurs[0]}
                      />
                    </PublicationCardContainer>
                  );
                })}
              </PublicationGrid>
            
            ) : (
              <p>Aucune publication ne correspond aux filtres sélectionnés.</p>
            )}

            <Defiler
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageClick={handlePageClick}
            />

          </main>

          <aside className="filter">

            <FilterStyle>
              
            <FilterTitle>
              Filtrer par <HorizontalLine />
            </FilterTitle>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={publicationTitleFilter}
                  onChange={handlePublicationTitleFilterChange}
                  placeholder="Recherche par titre"
                />
              </div>
            </InputSection>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={ResearcherFilter}
                  onChange={handleResearcherNameFilterChange}
                  placeholder="Recherche par chercheur"
                />
              </div>
            </InputSection>

            <StyledSelect
              value={thematiqueFilter}
              onChange={(e) => handleThematiqueFilterChange(e.target.value)}
            >
              <SelectOption value="">Toutes les thematiques</SelectOption>
              {Thematiques.map((thematique, index) => (
                <SelectOption key={index} value={thematique.nom}>
                  {thematique.nom}
                </SelectOption>
              ))}
            </StyledSelect>

            {sousThematiquesSelectFilter.length > 0 && (
                <><Label><Horizontal />Sous-Thématiques</Label>
                <SousThematiqueGroup>
                <CheckboxContainer>
                  {sousThematiquesSelectFilter.map((sousThematique) => (
                    <CheckboxLabel key={sousThematique.id}>
                      <CheckboxInput
                        type="checkbox"
                        checked={sousThematiqueFilter.includes(sousThematique.nom.toLowerCase())}
                        onChange={() => handleSousThematiqueFilterChange(sousThematique.nom.toLowerCase())}
                      />
                      {sousThematique.nom}
                    </CheckboxLabel>
                  ))}
                </CheckboxContainer>
              </SousThematiqueGroup></>
            )}

            <ReinitialiserButton onClick={handleResetFilters}>
              Réinitialiser les filtres
            </ReinitialiserButton>
            
            </FilterStyle>
          
          </aside>

        </div>

        <div className="videos">
            
          <h1 className="mainTitle" id="videos">Vidéos</h1>

          <VideosContainer>
            {videos.map((video) => (
              <VideoContainer>
                <h4>{video.title}</h4>
                <iframe width="1850" height="799" src={video.url} title={video.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                
              </VideoContainer>
            ))}
          </VideosContainer>

        </div>
        
        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>
      
      <footer>
        <Footer />
      </footer>

      {showCreatePopup && (
        <CreatePubPopup
          onSave={handleCreateCustomPublication}
          onClose={handleCloseCreatePopup}
        />
      )}

    </div>

  );
}

export default Ressources;
