import styled from "styled-components";

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  margin-right: 5%;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
`;
export const OtherInfos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ChercheurEtabs = styled.h2`
  display: flex;
  align-items: center;
  margin-left: 5%;
`;

export const Etab = styled.a`
  font-size: 17px;
  font-weight: lighter;
  margin-left: 5%;
   white-space: nowrap; // Empêche le texte de passer à la ligne suivante
  overflow: none;    // Cache le texte qui dépasse le conteneur
  flex: 1; // Permet à l'élément de prendre l'espace disponible
`;

export const ChercheurName = styled.h2`
  font-size: 40px;
  font-weight: bold;
  color: black;
  margin-top: 3%;
  margin-left: 5%;
`;

export const ChercheurImage = styled.div`
  width: 300px;
  height: 300px;
  background-image: ${({ backgroundImage }) => `${backgroundImage}`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5%;
`;

export const SimilarEventsTitle = styled.h3`
  margin-top: 100px;
`;

export const ThematiquesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: -5%;
  margin-bottom: 5%;
  margin-top: 10%;
  gap: 10px;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: red;
  margin-right: 8px;
`;

export const PublicationGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px ;
  justify-content: center;
  padding-top: 15px;
`;

export const PublicationCardContainer = styled.div`
  flex: 0 0 300px;
  width: 500px;
  margin-bottom: 20px;
`;