import React, { useState, useEffect, useContext } from "react";
import { PropagateLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

import { AuthContext } from "../helpers/AuthContext";

import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import EventCard from "../components/EventCard";
import Footer from "../components/Footer";
import Defiler from "../components/Defiler";

import "../styles/Commun.css";
import { EventGrid, EventCardContainer } from "../styles/Agenda.style";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { StyledSelect, InputSection, Input, StyledSearchIcon,
         StyledCalendarSearch, DatePickerContainer, HorizontalLine,
          ReinitialiserButton, FilterTitle, FilterStyle } from "../styles/Filter.style";


function Agenda() {
  
  const [events, setEvents] = useState([]);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const endpoint = authState.status ? 'combined' : 'public';

    axios.get(`https://back.r3mob.fr/event/${endpoint}`).then((res) => {
      setEvents(res.data);
    }).catch(error => {
      console.error('Error fetching events:', error);
    });
  }, [authState.status]);
  
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Ajoute un zéro devant le jour et le mois si nécessaire
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const currentDate = new Date();

  // Ajoutez des états pour les filtres
  const [eventNameFilter, setEventNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [eventLocationFilter, setEventLocationFilter] = useState("Tous"); // Par défaut, évènements en presentiel et distanciel
  var [loading, setLoading] = useState(true);
  const [sortType, setSortType] = useState("descending"); // Par défaut, tri decroissant
  const [eventStatusFilter, setEventStatusFilter] = useState("Tous"); // Par défaut, tous les évènements

  const [currentPage, setCurrentPage] = useState(1);
  
  useEffect(() => {
    setCurrentPage(1);
  }, [eventStatusFilter]);
  
  // Obtenir les événements à afficher sur la page actuelle avec filtres appliqués
  const filteredEvents = events
    .filter((event) => {
      // Appliquer les filtres ici
      const eventNameMatches = event.nom
        .toLowerCase()
        .includes(eventNameFilter.trim().toLowerCase());
      const dateMatches =
        dateFilter === "" ||
        formatDate(event.startDateTime) === formatDate(dateFilter);
      const eventLocationMatches =
        eventLocationFilter === "Tous" ||
        event.locationType === eventLocationFilter;
      return eventNameMatches && dateMatches && eventLocationMatches;
    })
    .filter((event) => {
      // Apply the event status filter
      if (eventStatusFilter === "Passés") {
        return new Date(event.startDateTime) < currentDate;
      } else if (eventStatusFilter === "Futurs") {
        return new Date(event.startDateTime) > currentDate;
      } else if (eventStatusFilter === "En cours") {
        return (
          new Date(event.startDateTime) <= currentDate &&
          new Date(event.endDateTime) >= currentDate
        );
      }
      return true; // Show all events when "Tous" is selected
    })
    .sort((a, b) => {
      // Tri des événements en fonction du type de tri
      loading = false;
      if (sortType === "ascending") {
        return new Date(a.startDateTime) - new Date(b.startDateTime);
      } else {
        return new Date(b.startDateTime) - new Date(a.startDateTime);
      }
    });

  const eventsPerPage = 6;
  const totalPages = Math.ceil(filteredEvents.length / eventsPerPage);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  // Fonction pour aller à la page choisie
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /* Fonctions pour gérer les changements de filtres */

  const handleEventNameFilterChange = (event) => {
    setEventNameFilter(event.target.value);
    setCurrentPage(1); 
  };

  const handleDateFilterChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      setDateFilter(formattedDate);
    } else {
      setDateFilter("");
    }
    setCurrentPage(1);
  };

  const handleEventLocationFilterChange = (event) => {
    setEventLocationFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setEventNameFilter("");
    setDateFilter("");
    setEventLocationFilter("Tous");
    setEventStatusFilter("Tous");
    setCurrentPage(1);
  };

  return (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle>{"> "} Agenda</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">
      <div className="events section">
      
        <main>
            
          <h1 className="mainTitle">Évènements</h1>
          
          {loading === true ? (
            <PropagateLoader cssOverride={{
              "display": "flex",
              "justifyContent": "center", 
              "alignItems": "center", 
              "height": "100vh" }}
            color="#36d7b7" />
          ) : (
            currentEvents.length === 0 ? (
              <p>Aucun événement à afficher pour les filtres sélectionnés.</p>
            ) : (

              <EventGrid>
                {currentEvents.map((value, key) => {
                  return (
                    <EventCardContainer key={key}>
                      <EventCard
                        id={value.id}
                        date={formatDate(value.startDateTime)}
                        title={value.nom}
                        //description={value.description}
                        locationType={value.locationType}
                        eventType={value.eventType}
                        location={value.locationType === "Visio" ? "En ligne" : value.location}
                        registrationLink={value.lienInscription}
                        imageUrl={`events_imgs/${value.id}.jpg`}
                        fallbackImageUrl={"events_imgs/event_default.jpg"}
                      />
                    </EventCardContainer>
                  );
                })}
              </EventGrid>

            )
          )}

          <Defiler
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageClick={handlePageClick}
          />

          </main>
          
          <aside className="filter">

            <FilterStyle>

              <FilterTitle>
                Filtrer par <HorizontalLine />
              </FilterTitle>

              <InputSection>
                <div style={{ position: "relative" }}>
                  <StyledSearchIcon /> {/* Remplacez cela par votre logique de recherche */}
                  <Input
                    type="text"
                    value={eventNameFilter}
                    onChange={handleEventNameFilterChange}
                    placeholder="Rechercher par mot clé"
                  />
                </div>
              </InputSection>

              <DatePickerContainer>
                <DatePicker
                  selected={dateFilter !== "" ? new Date(dateFilter) : null}
                  onChange={(date) => handleDateFilterChange(date)}
                  dateFormat="dd/MM/yyyy"
                  startDate={currentDate}
                  placeholderText="Rechercher par date"
                />
                {dateFilter === "" && (
                  <StyledCalendarSearch />
                )}
                {dateFilter !== "" && (
                  <span
                    className="react-datepicker__clear-icon"
                    onClick={() => handleDateFilterChange(null)}
                  >
                    x
                  </span>
                )}
              </DatePickerContainer>

              
              <StyledSelect
                value={eventLocationFilter}
                onChange={handleEventLocationFilterChange}
              >
                <option value="Tous">Visio et Présentiel</option>
                <option value="Visio">Visio</option>
                <option value="Présentiel">Présentiel</option>
              </StyledSelect>
              <StyledSelect
                value={eventStatusFilter}
                onChange={(e) => setEventStatusFilter(e.target.value)}
              >
                <option value="Tous">Tous les évènements</option>
                <option value="Passés">Événements passés</option>
                <option value="Futurs">Événements futurs</option>
                <option value="En cours">Événements en cours</option>
              </StyledSelect>
              

              <ReinitialiserButton onClick={handleResetFilters}>Réinitialiser les filtres</ReinitialiserButton>
              
              <FilterTitle>
                Trier par <HorizontalLine />
              </FilterTitle>
              
              <StyledSelect
                value={sortType}
                onChange={(e) => setSortType(e.target.value)}
              >
                <option value="ascending">Date croissante</option>
                <option value="descending">Date décroissante</option>
              </StyledSelect>

            </FilterStyle>

          </aside>

        </div>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer/>
      </footer>

    </div>
  );
}

export default Agenda;
