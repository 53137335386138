import styled from "styled-components";
import Colors from "./Colors";


export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  width: 100%; 
  /* overflow-x: auto;  */

`;

export const StatusButton = styled.button`
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: ${props => props.isPrivate === 't' ? 'red' : 'gray'};
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${props =>
      props.isPrivate === 't' ? 'darkred' : 'darkgray'};
  }
`;

export const StatusButtonEquipe = styled.button`
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: ${props => props.isEquipe === true ? 'red' : 'gray'};
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${props =>
      props.isEquipe === true ? 'darkred' : 'darkgray'};
  }
`;

export const ChooseButtonsContainer = styled.div`
  margin-bottom: 20px;
  align-items: center;
  margin-top: 120px;
`;

export const ChooseButton = styled.button`
  background-color: ${(props) => (props.active ? Colors.color2 : Colors.color5)};
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 0;
  margin-left: 0;
`;

export const Container = styled.div`
  padding: 20px;
  height: 100vh;
  width: 70%;
`;

export const Button = styled.button`
  background-color: ${Colors.color4};
  color: ${Colors.color3};
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 10px;
  &:hover{
    background-color: ${Colors.color6};
    color: ${Colors.color4};
  }
`;

export const Table = styled.table`
  width: 100%; 
  max-width: 100%;
  table-layout: auto; 

  @media (max-width: 768px) {
    font-size: 14px; /* Réduisez la taille de la police sur les petits écrans */
  }
`;

export const Th = styled.th`
  background-color: ${Colors.color2};
  color: #fff;
  padding: 10px;
  text-align: center;
`;

export const Td = styled.td`
  border: 1px solid #ccc;
  padding: 10px;
  word-break: break-word;
  text-align: center;
`;

export const ButtonDelete = styled.button`
  background-color: red;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  &:hover{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const ButtonEdit = styled.button`
  background-color: orange;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  &:hover{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const TdActions = styled.td`
  display: flex;
  gap: 10px;
  justify-content: center;
  /* border: 1px solid #ccc; */
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

export const Validate =styled.button`
  padding: 8px 16px;
  width: fit-content;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: green;
  color: ${Colors.color3};
  &:hover{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const StyledTd = styled.td`
  border: 1px solid #ccc;
  padding: 10px 0px 10px 0px;
  word-break: break-all;
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

export const ConfirmButton = styled.button`
  width: auto;
  height: 45px;
  margin: 10px;
  padding: 15px 20px 20px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: ${Colors.color2};
  color: ${Colors.color3};
  &:hover{
    background-color: ${Colors.color6};
    color: ${Colors.color1};
  }
`;

export const ResetButton = styled.button`
  width: auto;
  height: 45px;
  margin: 10px;
  padding: 15px 20px 20px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: orange;
  color: ${Colors.color3};
  &:hover{
    background-color: ${Colors.color6};
    color: ${Colors.color1};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  border: none;
`;

export const Textarea = styled.textarea`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical; /* Allow the textarea to resize vertically */
`;