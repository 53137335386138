import React, { useState, useEffect } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from "axios";

import CarteProjet from "../components/CarteProjet";
import Thematiques from "../components/Thematiques";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import Defiler from "../components/Defiler";

import "../styles/Commun.css";

import { StyledSelect, InputSection, Input, StyledSearchIcon,
  HorizontalLine, ReinitialiserButton, FilterTitle, FilterStyle,
  CheckboxContainer, CheckboxLabel, CheckboxInput, SousThematiqueGroup, 
  Label, Horizontal } from "../styles/Filter.style";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import {ProjetCardContainer, ProjetGrid} from "../styles/Projet.style";

function Projet() {

  /* Déclaration Projets */
  const [projets, setProjets] = useState([]);
  const [allThematiques, setAllThematiques] = useState([]);
  const [allSousThematiques, setAllSousThematiques] = useState([]);
  const [allEnjeux, setAllEnjeux] = useState([]);
  const [etatFilter, setEtatFilter] = useState("");
  const [projetTitleFilter, setProjetTitleFilter] = useState("");
  const [etablissementFilter, setEtablissementFilter] = useState("");
  const [enjeuxFilter, setEnjeuxFilter] = useState("");
  const [thematiqueFilter, setThematiqueFilter] = useState("");
  const [sousThematiqueFilter, setSousThematiqueFilter] = useState([]);
  const [sousThematiquesSelectFilter, setSousThematiquesSelectFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  /*Déclaration AAP */
  const [aap, setAap] = useState([]);
  const [currentPageAap, setCurrentPageAap] = useState(1);
  const [aapTitleFilter, setAapTitleFilter] = useState("");
  const [aapEtablissementFilter, setAapEtablissementFilter] = useState("");


  var [loading, setLoading] = useState(false);
  const currentHash = window.location.hash;

  useEffect(() => {
    if (currentHash === "#projets") {
      // Si l'ancre est "#videos", faites défiler jusqu'à la section "videos"
      const projetsSection = document.getElementById("projets");
      if (projetsSection) {
        projetsSection.scrollIntoView({ behavior: "smooth" });
      }
    } else if (currentHash === "#aap") {
      // Si l'ancre est "#publications", faites défiler jusqu'à la section "publications"
      const aapSection = document.getElementById("aap");
      if (aapSection) {
        aapSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentHash]);


  useEffect(() => {
    axios.get(`https://back.r3mob.fr/projet`).then((res) => {
      setProjets(res.data);
    });
    axios.get(`https://back.r3mob.fr/sousThematique`).then((res) => {
      setAllSousThematiques(res.data);
    });
    axios.get(`https://back.r3mob.fr/enjeux`).then((res) => {
      setAllEnjeux(res.data);
    });
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      setAllThematiques(res.data);
    });
  }, []);
  
  useEffect(() => {
    axios.get(`https://back.r3mob.fr/aap`).then((res) => {
      setAap(res.data);
    });
  }, []);

  /* Filtre projet */
  const filteredProjets = projets
    .filter((projet) => {
      const projetTitleMatches = projet.nom
        .toLowerCase()
        .includes(projetTitleFilter.trim().toLowerCase());
      return projetTitleMatches;
    })
    .filter((projet) =>
      projet.etablissements.some((etablissement) => {
        const etablissementNames = etablissement.split(" "); 
        const filterValue = etablissementFilter.toLowerCase();
        return etablissementNames.some((part) =>
          part.toLowerCase().startsWith(filterValue.trim())
        );
      })
    )
    .filter((projet) => {
      if (thematiqueFilter === "") {
        return true; 
      }
      return projet.Thematique_projets.some((thematiqueProjet) => {
        const thematiqueNom = thematiqueProjet.Thematique?.nom || "";
        const sousThematiqueNom = thematiqueProjet.SousThematique?.Thematique?.nom || "";
      return sousThematiqueNom.toLowerCase() === thematiqueFilter.toLowerCase() || thematiqueNom.toLowerCase() === thematiqueFilter.toLowerCase();
    });
    })
    .filter((projet) => {
      if (sousThematiqueFilter.length === 0) {
        return true; 
      }
      return projet.Thematique_projets.some((thematiqueProjet) => {
        const sousThematiqueNom = thematiqueProjet.SousThematique?.nom || "";
        return sousThematiqueFilter.includes(sousThematiqueNom.toLowerCase());
      });
    })
    .filter((projet) => {
      if (etatFilter === "") {
        return true; 
      }
      return projet.etat === etatFilter;
    })
    .filter((projet) => {
      //loading = false;
      if (enjeuxFilter === "") {
        return true; 
      }
      return projet.Enjeux_projets.some((enjeuxProjet) => {
        const enjeuxNom = enjeuxProjet.Enjeux?.nom || "";
        return enjeuxNom.toLowerCase() === enjeuxFilter.toLowerCase();
      });
    });


  const projetsPerPage = 6;
  const totalPages = Math.ceil(filteredProjets.length / projetsPerPage);
  const indexOfLastProjet = currentPage * projetsPerPage;
  const indexOfFirstProjet = indexOfLastProjet - projetsPerPage;
  const currentProjets = filteredProjets.slice(indexOfFirstProjet, indexOfLastProjet);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fonctions pour gérer les changements de filtres
  const handleProjetTitleFilterChange = (title) => {
    setProjetTitleFilter(title.target.value);
    setCurrentPage(1);
  };

  const handleEtablissementNameFilterChange = (chercheur) => {
    setEtablissementFilter(chercheur.target.value);
    setCurrentPage(1);
  };

  const handleThematiqueFilterChange = (selectedThematique) => {
    setThematiqueFilter(selectedThematique);
    const sousThematiquesFiltrees = allSousThematiques.filter(
      (sousThematique) => {
        return sousThematique.Thematique.nom === selectedThematique;
      }
    );
    setSousThematiquesSelectFilter(sousThematiquesFiltrees);
    setCurrentPage(1);
  };

  const handleSousThematiqueFilterChange = (selectedSousThematique) => {
    if (sousThematiqueFilter.includes(selectedSousThematique)) {
      setSousThematiqueFilter(
        sousThematiqueFilter.filter(
          (sousThematique) => sousThematique !== selectedSousThematique
        )
      );
    } else {
      setSousThematiqueFilter([...sousThematiqueFilter, selectedSousThematique]);
    }
    setCurrentPage(1);
  };

  const handleEtatFilterChange = (selectedEtat) => {
    setEtatFilter(selectedEtat);
    setCurrentPage(1);
  };

  const handleEnjeuxFilterChange = (selectedEnjeux) => {
    setEnjeuxFilter(selectedEnjeux);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setProjetTitleFilter("");
    setEtablissementFilter("");
    setThematiqueFilter("");
    setEtatFilter("");
    setSousThematiqueFilter([]);
    setSousThematiquesSelectFilter([]);
    setEnjeuxFilter("");
    setCurrentPage(1);
  };

  /* Filtre app */

  const filteredAap = aap
  .filter((projet) => {
    const projetTitleMatches = projet.nom
      .toLowerCase()
      .includes(aapTitleFilter.trim().toLowerCase());
    return projetTitleMatches;
  })
  .filter((projet) =>
    projet.etablissements.some((etablissement) => {
      const etablissementNames = etablissement.split(" ");
      const filterValue = aapEtablissementFilter.toLowerCase();
      return etablissementNames.some((part) =>
        part.toLowerCase().startsWith(filterValue.trim())
      );
    })
  );

  const aapPerPage = 6;
  const totalPagesAap = Math.ceil(filteredAap.length / aapPerPage);
  const indexOfLastAap = currentPageAap * aapPerPage;
  const indexOfFirstAap = indexOfLastAap - aapPerPage;
  const currentAap = filteredAap.slice(indexOfFirstAap, indexOfLastAap);

  const handlePageClickAap = (pageNumber) => {
    setCurrentPageAap(pageNumber);
  };

  // Fonctions pour gérer les changements de filtres pour les AAP
  const handleAapTitleFilterChange = (title) => {
    setAapTitleFilter(title.target.value);
    setCurrentPageAap(1);
  };

  const handleAapEtablissementNameFilterChange = (chercheur) => {
    setAapEtablissementFilter(chercheur.target.value);
    setCurrentPageAap(1);
  };

  const handleResetAapFilters = () => {
    setAapTitleFilter("");
    setAapEtablissementFilter("");
    setCurrentPageAap(1);
  };


  return (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "} Projets</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">

      <div className="projets section">

          <main>
            
            <h1 className="mainTitle" id="projets">Projets</h1>
            
            {loading === true ? (
              <PropagateLoader cssOverride={{
                  "display": "flex",
                  "justifyContent": "center",
                  "alignItems": "center",
                  "height": "100vh"}}
              color="#36d7b7" />
            ) : (

            currentProjets.length != 0 ? (

            <ProjetGrid>
              {currentProjets.map((value, key) => {
                return (
                  <ProjetCardContainer key={key}>
                    <CarteProjet
                      id={value.id}
                      title={value.nom}
                      url={value.url}
                      imageUrl="projet.png"
                      fallbackUrl="../default_user.png"
                      etablissements={value.etablissements}
                      enjeux={value.Enjeux_projets}
                    />
                  </ProjetCardContainer>
                );
              })}
            </ProjetGrid>

            ) : (
              <p>Aucun projet ne correspond aux filtres sélectionnés.</p>
            ))}

            <Defiler
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageClick={handlePageClick}
            />

          </main>

          <aside className="filter">

            <FilterStyle>
            <FilterTitle>
              Filtrer par <HorizontalLine />
            </FilterTitle>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={projetTitleFilter}
                  onChange={handleProjetTitleFilterChange}
                  placeholder="Recherche par titre"
                />
              </div>
            </InputSection>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={etablissementFilter}
                  onChange={handleEtablissementNameFilterChange}
                  placeholder="Recherche par établissement"
                />
              </div>
            </InputSection>

            <StyledSelect
              value={etatFilter}
              onChange={(e) => handleEtatFilterChange(e.target.value)}
            >
              <option value="">Tous les projets</option>
              <option value="EC">En cours </option>
              <option value="F">Futur </option>
              <option value="P">Passé </option>
              <option value="A">Non renseigné </option>
            </StyledSelect>

            <StyledSelect
              value={enjeuxFilter}
              onChange={(e) => handleEnjeuxFilterChange(e.target.value)}
            >
              <option value="">Tous les Enjeux</option>
              {allEnjeux.map((enjeux, index) => (
                <option key={index} value={enjeux.nom}>
                  {enjeux.nom}
                </option>
              ))}
            </StyledSelect>

            <StyledSelect
              value={thematiqueFilter}
              onChange={(e) => handleThematiqueFilterChange(e.target.value)}
            >
              <option value="">Toutes les thematiques</option>
              {Thematiques.map((thematique, index) => (
                <option key={index} value={thematique.nom}>
                  {thematique.nom}
                </option>
              ))}
            </StyledSelect>

            {sousThematiquesSelectFilter.length > 0 && (
                <><Label><Horizontal />Sous-Thématiques</Label>
                <SousThematiqueGroup>
                <CheckboxContainer>
                  {sousThematiquesSelectFilter.map((sousThematique) => (
                    <CheckboxLabel key={sousThematique.id}>
                      <CheckboxInput
                        type="checkbox"
                        checked={sousThematiqueFilter.includes(sousThematique.nom.toLowerCase())}
                        onChange={() => handleSousThematiqueFilterChange(sousThematique.nom.toLowerCase())}
                      />
                      {sousThematique.nom}
                    </CheckboxLabel>
                  ))}
                </CheckboxContainer>
              </SousThematiqueGroup></>
            )}

            <ReinitialiserButton onClick={handleResetFilters}>
              Réinitialiser les filtres
            </ReinitialiserButton>
            </FilterStyle>
          
          </aside>

        </div>

        <div className="apps section">
          
          <main>

            <h1 className="mainTitle" id="aap">Appel à projets</h1>

            {loading === true ? (
              <PropagateLoader cssOverride={{
                  "display": "flex",
                  "justifyContent": "center",
                  "alignItems": "center",
                  "height": "100vh"}}
              color="#36d7b7" />
            ) : (

            currentAap.length != 0 ? (

            <ProjetGrid>
              {currentAap.map((value, key) => {
                return (
                  <ProjetCardContainer key={key}>
                    <CarteProjet
                      id={value.id}
                      title={value.nom}
                      url={value.url}
                      imageUrl="aap.png"
                      fallbackUrl="../default_user.png"
                      etablissements={value.etablissements}
                    />
                  </ProjetCardContainer>
                );
              })}
            </ProjetGrid>

            ) : (
              <p>Aucun appel à projet ne correspond aux filtres sélectionnés.</p>
            ))}

            <Defiler
              currentPage={currentPageAap}
              totalPages={totalPagesAap}
              handlePageClick={handlePageClickAap}
            />

          </main>
          
          <aside className="filter">

            <FilterStyle>
            <FilterTitle>
              Filtrer par <HorizontalLine />
            </FilterTitle>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={aapTitleFilter}
                  onChange={handleAapTitleFilterChange}
                  placeholder="Recherche par titre"
                />
              </div>
            </InputSection>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  value={aapEtablissementFilter}
                  onChange={handleAapEtablissementNameFilterChange}
                  placeholder="Recherche par établissement"
                />
              </div>
            </InputSection>

            <ReinitialiserButton onClick={handleResetAapFilters}>
              Réinitialiser les filtres
            </ReinitialiserButton>
            </FilterStyle>
          
          </aside>

        </div>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

  );

}

export default Projet;
