import React, { useState, useContext } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";

import { InputSection, Label, Input, ConfirmButton, Loader } from "../styles/Upload.style"; // Importez le Loader si vous en avez un

function Upload() {

  const [selectedZipFile, setSelectedZipFile] = useState(null);
  const [selectedJsonFile, setSelectedJsonFile] = useState({
    thoughts: null,
    links: null,
    calendarevents: null,
    attachments: null,
  });
  const [isLoading, setIsLoading] = useState(false); // État de chargement
  const { authState } = useContext(AuthContext);

  const handleUploadAll = async () => {
    if (
      !selectedZipFile ||
      !selectedJsonFile.thoughts ||
      !selectedJsonFile.links ||
      !selectedJsonFile.calendarevents ||
      !selectedJsonFile.attachments
    ) {
      alert("Please select all JSON files before uploading.");
      return;
    }

    setIsLoading(true); // Démarrer le chargement

    await handleZipFileUpload();
    await handleJsonFileUpload();
    await VerifyDataBase().then((res) => {
      alert("Upload completed successfully!");
      setIsLoading(false); // Arrêter le chargement
    });
  };

  const handleZipFileUpload = async () => {
    if (!selectedZipFile) {
      console.error("Aucun fichier ZIP sélectionné");
      return;
    }
    const formData = new FormData();
    formData.append("zipFile", selectedZipFile);
    try {
      const response = await axios.post(
        "https://back.r3mob.fr/uploadBase/folders",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload de fichier ZIP (dossiers) réussi", response.data);
    } catch (error) {
      console.error("Upload de fichier ZIP (dossiers) échoué", error);
    }
  };

  const handleJsonFileUpload = async () => {
    if (!selectedJsonFile) {
      console.error("Aucun fichier JSON sélectionné");
      return;
    }
    for (const [key, value] of Object.entries(selectedJsonFile)) {
      if (!value) {
        console.error(`Aucun fichier JSON ${key} sélectionné`);
        return;
      }
      const formData = new FormData();
      formData.append("jsonFile", value);
      try {
        const response = await axios.post(
          "https://back.r3mob.fr/uploadBase/json",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Upload de fichier JSON réussi", response.data);
      } catch (error) {
        console.error("Upload de fichier JSON échoué", error);
      }
    }
  };

  const handleZipFileSelection = (event) => {
    const file = event.target.files[0];
    setSelectedZipFile(file);
  };

  const VerifyDataBase = async () => {
    try {
      const verifyResponse = await axios.get(
        "https://back.r3mob.fr/uploadBase/verifyFiles"
      );
      if (verifyResponse.data.error) {
        alert(verifyResponse.data.error);
      } else {
        console.log("BD Verified!!!!");
      }
    } catch (error) {
      console.error("Vérification de la base de données échouée", error);
    }
  };

  const ClearDataBase = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("https://back.r3mob.fr/brain/clear");
        console.log("Vidage de la base de données réussi", response.data);
        resolve(response.data);
      } catch (error) {
        console.error("Vidage de la base de données échoué", error);
        reject(error);
      }
    });
  };

  const BackUpDataBase = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          "https://back.r3mob.fr/brain/backups"
        );
        console.log("Backup de la base de données réussi", response.data);
        resolve(response.data);
      } catch (error) {
        console.error("Backup de la base de données échoué", error);
        reject(error);
      }
    });
  };

  const UpdateDataBase = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("https://back.r3mob.fr/brain");
        console.log("Mise à jour de la base de données réussie");
        resolve(response.data);
      } catch (error) {
        console.error("Mise à jour de la base de données échouée", error);
        alert(error);
        reject(error);
      }
    });
  };

  const handleClearAndUpdate = async () => {
    setIsLoading(true); // Démarrer le chargement
    try {
      await BackUpDataBase().then((res) => {
        console.log("BD backed up!!!!");

        setTimeout(async () => {
          await ClearDataBase().then(async (res) => {
            console.log("BD cleared!!!!");

            setTimeout(async () => {
              await UpdateDataBase().then((res) => {
                alert("Update completed successfully!");
                setIsLoading(false); // Arrêter le chargement
              });
            }, 10000); // 10 secondes
          }, 2000); // 2 secondes
        });
      });
    } catch (error) {
      console.error("Erreur lors de l'upload", error);
      setIsLoading(false); // Arrêter le chargement en cas d'erreur
    }
  };

  return authState.status === true && authState.role === true ? (
    <div className="body">
      <div className="main" style={{ marginTop: "50px" }}>
        <main>
          <InputSection>
            <Label>Télécharger un fichier ZIP (pour les dossiers)</Label>
            <Input type="file" accept=".zip" onChange={handleZipFileSelection} />
          </InputSection>

          <InputSection>
            <Label>Télécharger un thoughts.json</Label>
            <Input
              type="file"
              accept=".json"
              onChange={(event) => {
                const file = event.target.files[0];
                setSelectedJsonFile(prevState => ({ ...prevState, thoughts: file }));
              }}
            />
          </InputSection>

          <InputSection>
            <Label>Télécharger un links.json</Label>
            <Input
              type="file"
              accept=".json"
              onChange={(event) => {
                const file = event.target.files[0];
                setSelectedJsonFile(prevState => ({ ...prevState, links: file }));
              }}
            />
          </InputSection>

          <InputSection>
            <Label>Télécharger un calendarevents.json</Label>
            <Input
              type="file"
              accept=".json"
              onChange={(event) => {
                const file = event.target.files[0];
                setSelectedJsonFile(prevState => ({ ...prevState, calendarevents: file }));
              }}
            />
          </InputSection>

          <InputSection>
            <Label>Télécharger un attachments.json</Label>
            <Input
              type="file"
              accept=".json"
              onChange={(event) => {
                const file = event.target.files[0];
                setSelectedJsonFile(prevState => ({ ...prevState, attachments: file }));
              }}
            />
          </InputSection>

          <InputSection>
            <ConfirmButton onClick={handleUploadAll} disabled={isLoading}>Téléverser les fichiers</ConfirmButton>
            <ConfirmButton onClick={handleClearAndUpdate} disabled={isLoading}>Mettre à jour la base de données</ConfirmButton>
          </InputSection>

          {isLoading && <Loader>Chargement en cours...</Loader>} {/* Afficher l'indicateur de chargement */}
        </main>

        <aside className="mobile">
          <ScrollButton />
        </aside>
      </div>
    </div>
  ) : (
    <Navigate to="/404" />
  );
}

export default Upload;
