import styled from "styled-components";
import Colors from "../styles/Colors";
import { Link } from "react-router-dom";

export const SecondaryTitle = styled.h2`
  color: ${Colors.color2};
  font-size: 2rem;
  margin-top: 70px;
  /* align-self: left; */
  text-align: center;
`;
export const PublicationGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 15px;
`;

export const PublicationCardContainer = styled.div`
  flex: 0 0 300px;
  width: 500px;
  margin-bottom: 20px;
`;

export const EventGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 15px;

  @media (max-width: 2189px) {
    gap: 20px 100px;
  }
  @media (max-width: 1608px) {
    gap: 25px;
  }
  @media (max-width: 1075px) {
    gap: 10px;
  }
`;

export const EventCardContainer = styled.div`
  width: 400px;

  @media (min-width: 2189px) {
    height: auto;
    width: auto;
  }
  @media (max-width: 2189px) {
    gap: 20px 100px;
    width: auto;
  }
  @media (max-width: 1608px) {
    gap: 5px;
    width: auto;
  }
  @media (max-width: 990px) {
    width: auto;
  }
`;

export const PersoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding-top: 15px;
`;

export const PersoCardContainer = styled.div`
  width: auto;
`;

export const CardContainer = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  margin-top: 100px;
  animation: ${(props) =>
    props.playAnimation ? "rotate 0.5s ease, zoom 0.5s ease" : "none"};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 30%;
  background-color: white;
  z-index: 1;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  opacity: 0.8;
  margin-left:10%;
  border-radius: 10px;
  /* overflow: hidden; Empêche le débordement du contenu */
`;

export const Title = styled.h2`
  color: red;
  font-size: 1.2rem;
  margin-top: 0;
  @media (max-width: 990px) {
    font-size: 0.9rem;
  }
`;
export const Paragraph = styled.p`
  color: #333;
  font-size: 0.8rem;
  margin-bottom: 0; /* Ajustez la marge selon vos besoins */
@media (max-width: 990px) {
    font-size: 0.5rem;
  }
`;

export const StyledSpan = styled.span`
  color: red;
  font-weight: bold;
`;

export const ImageSelectionButtons = styled.div`
  position: absolute;
  bottom: 10px; /* Ajustez la distance par rapport au bas de l'image */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const ImageSelectionButton = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.isSelected &&
    `
    cursor: auto;
    background-color: #333;
  `}
`;

export const EnSavoirPlus = styled.div`
    text-decoration: none;
    color: ${Colors.color3};
    background-color: ${Colors.color2};
    border-radius: 20px;
    display: inline-block;
    padding: 10px;
    margin: 20px;

    &:hover {
        color: ${Colors.color2};
        background-color: ${Colors.color6};
    }
`;

export const PageLink = styled(Link)`
  text-decoration: none;
  /* width: 100%;
  display: inline-flex;
  justify-content: center; 
  align-items: center; */
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%;
`;