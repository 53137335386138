import styled from 'styled-components';

export const ScrollButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  border: none;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  @media (max-width: 1329px) {
    height: 25px;
    width: 25px;
  }
`;
export const Icon = styled.img`
  color: white;
  background-image: ${({ icon }) => `url(${icon})`};
  height:60%;
  width: 60%;

  
`;
export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 1329px) {
    height: 30px;
    width: 30px;
  }
`;

export const TooltipContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px 20px 20px 20px;
  width: 200px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? "block" : "none")};
  font-size: 10px;
  @media (max-width: 1329px) {
    font-size: 7px;
    width: 120px;
    padding: 10px 10px 10px 10px;
  }
`;

export const SelectedThematiqueContainer = styled.div`
  background-color: whitesmoke;
  padding: 2px 5px 5px 5px;
  color: white;
  border: none;
  width: fit-content;
  font-size: x-small;
  word-wrap: break-word;
  margin-bottom: 10px;
  flex-direction: row;
    display: flex;
`;

export const Label = styled.p`
    margin-bottom: 5px;
    text-align: center;
    color: black;
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  color: red;
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  &:hover {
    color: #c82333;
  }
`;
