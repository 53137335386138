import React, { useEffect, useState } from "react";
import axios from "axios";

import { Table, Th, Td, TableContainer,
    StatusButtonEquipe } from "../styles/UpdateEvent.style";
import {  InputSectionEquipe, Input, StyledSearchIcon, 
    FilterStyleEquipe } from "../styles/Filter.style";

function UpdateEquipeReseau() {

    const [persos, setPersos] = useState([]);
    const [persoNameFilter, setPersoNameFilter] = useState("");

    useEffect(() => {
        axios.get(`https://back.r3mob.fr/perso`).then((res) => {
          setPersos(res.data);
          console.log(res.data); 
        });
    }, []);

    const handleSearchInputChange = (name) => {
        setPersoNameFilter(name.target.value);
    };

    const handleChangeIsEquipe = async (persoId) => {
        try {
          const persoToUpdate = persos.find(perso => perso.id === persoId);
          const updatedPerso = {
            ...persoToUpdate,
            isEquipe: persoToUpdate.isEquipe === true ? false : true
          };
          const res = await axios.put(
            `https://back.r3mob.fr/perso/changeEquipe/${persoId}`,
            updatedPerso
          );
          if (res.status === 200) {
            fetchPersos();
          }
        } catch (error) {
          console.error(error);
        }
      };
      
      const fetchPersos = async () => {
        const res = await axios.get(`https://back.r3mob.fr/perso`);
        setPersos(res.data);
      };

    const filteredPersos = persos
        .filter((perso) => {
            const filter = persoNameFilter.trim().toLowerCase();
            return filter === "" || perso.username.toLowerCase().includes(filter); 
        });

    return (
        <>
        
        <FilterStyleEquipe>
              <InputSectionEquipe>
                <div style={{ position: "relative" }}>
                  <StyledSearchIcon />
                  <Input
                    type="text"
                    value={persoNameFilter}
                    onChange={handleSearchInputChange}
                    placeholder="Rechercher par nom"
                  />
                </div>
              </InputSectionEquipe>
        </FilterStyleEquipe>
        

        <TableContainer>
    
          <Table>
    
            <thead>
                <tr>
                    <Th >Prénom Nom</Th>
                    <Th >Membre de l'équipe du réseau</Th>
                </tr>
            </thead>
    
            <tbody>
              {filteredPersos.map((perso) => (
                <tr key={perso.id}>
                    <Td >{perso.username}</Td>
                    <Td>
                        <StatusButtonEquipe isEquipe={perso.isEquipe} onClick={() => handleChangeIsEquipe(perso.id)}>
                            {perso.isEquipe === true ? 'Responsable' : 'Participant'}
                        </StatusButtonEquipe>
                    </Td>
                </tr>
              ))}
            </tbody>
    
          </Table>
    
        </TableContainer>
        </>
      );

}

export default UpdateEquipeReseau;
