import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { PopupContainer, Title, CloseButton, PopupContent,
  ConfirmButton, ResetButton, ButtonContainer, Input,
  Textarea, Label, ImageSection, InputSection,
  ImageField, UploadArea, FileInput, FileInputLabel,
  FileInputContainer } from "../styles/EditEventPopup.style";


const CreateEventPopup = ({ onSave, onClose }) => {

  const [nom, setNom] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("events_imgs/event_default.jpg");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file); // Create a temporary URL for the selected image
      setSelectedImage(file);
      setImageUrl(newImageUrl); // Update the imageUrl to display the selected image
    }
  };

  const handleResetChanges = () => {
    // Reset all the fields here
    setNom(null);
    setStartDateTime(null);
    setLocation(null);
    setDescription(null);
    setSelectedImage(null);
    setImageUrl("events_imgs/event_default.jpg");
  };

  const handleSaveChanges = () => {
    // Combine date and time into a single string with the desired format
    const formattedDateTime = startDateTime.toISOString().slice(0, 16);
    const createdEvent = {
      nom,
      startDateTime: formattedDateTime,
      endDateTime: formattedDateTime,
      location,
      description,
      selectedImage,
    };
    onSave(createdEvent);
  };

  return (

    <PopupContainer>
      <PopupContent>

        <Title>Créer un événement</Title>

        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          <Label>Nom:</Label>
          <Input
            type="text"
            onChange={(e) => setNom(e.target.value)}
          />
        </InputSection>

        <InputSection>
          <Label>Date et Heure de début:</Label>
          <DatePicker
            selected={startDateTime}
            onChange={(date) => setStartDateTime(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="yyyy-MM-dd HH:mm"
            placeholderText="2021-05-20 18:00"
          />
        </InputSection>

        <InputSection>
          <Label>Location:</Label>
          <Input
            type="text"
            onChange={(e) => setLocation(e.target.value)}
          />
        </InputSection>

        <InputSection>
          <Label>Description:</Label>
          <Textarea
            rows="5"
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputSection>

        <ImageSection>
          <ImageField src={imageUrl} alt="Event Image" />
          <UploadArea>
            <FileInputContainer>
              <FileInputLabel>
                Sélectionner une image
                <FileInput type="file" onChange={handleImageUpload} />
              </FileInputLabel>
            </FileInputContainer>
          </UploadArea>
        </ImageSection>

        <ButtonContainer>
          <ResetButton onClick={handleResetChanges}>Réinitialiser</ResetButton>
          <ConfirmButton onClick={handleSaveChanges}>Confirmer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
    </PopupContainer>

  );

}

export default CreateEventPopup;
