import React, { useEffect, useState } from "react";
import axios from "axios";

import { Table, Th, Td, TableContainer } from "../styles/UpdateEvent.style";
import ScrollButton from "./ScrollButton";


function UpdateActeurCounts() {

  const [publications, setPublications] = useState([]);

  useEffect(() => {
    axios.get('https://back.r3mob.fr/publication').then((response) => {
        setPublications(response.data);
    });
  }, []);


  return (

    <>
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th >Description publication</Th>
            <Th >Nom chercheur</Th>
            <Th >Lien publication</Th>
          </tr>
        </thead>
        <tbody>
          {publications.map((publication,index) => (
            <tr key={index}>
              <Td /* style={{width: '30%'}} */>
                {publication.nom}
              </Td>
              <Td >{publication.chercheurs[0]}</Td>
              <Td>{publication.url}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>

    <aside className="mobile">
        <ScrollButton />
    </aside>
    </>
  
  );

};


export default UpdateActeurCounts;
