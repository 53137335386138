import React, { useState, useEffect, useContext } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

import { PopupContainer, Title, CloseButton, PopupContent,
  ConfirmButton, ResetButton, ButtonContainer, Input,
  Textarea, Label,  InputSection, ErrorMessage,ImageSection, 
  UploadArea, FileInput, FileInputLabel, FileInputContainer, ImageField,  
  CheckboxContainer, CheckboxLabel, CheckboxInput, SousThematiqueGroup } from "../styles/CreatePubPopup.style";

import { AuthContext } from "../helpers/AuthContext";


const EditPubPopup = ({ pubId, title, pubUrl, onSave, onClose, image }) => {

  const [isSaveClicked, setIsSaveClicked] = useState(false); 
  const { authState, setAuthState } = useContext(AuthContext);
  const [nom, setNom] = useState(title);
  const [url, setUrl] = useState(pubUrl);
  /* const [selectedImage, setSelectedImage] = useState(null); */
  const [allSousThematiques, setAllSousThematiques] = useState([]);
  const [allThematiques, setAllThematiques] = useState([]);
  const [selectedSousThematiques, setSelectedSousThematiques] = useState([]);
  const [selectedThematiques, setSelectedThematiques] = useState([]);
  const [sousThematiquesGrouped, setSousThematiquesGrouped] = useState({});
  const [publicationSousThematiques, setPublicationSousThematiques] = useState([]);
  const [initialSousThematiques, setInitialSousThematiques] = useState([]);
  const [initialThematiques, setInitialThematiques] = useState([]);
  const [initialSousThematiquesGrouped, setInitialSousThematiquesGrouped] = useState({});
  
  useEffect(() => {
    axios.get(`https://back.r3mob.fr/sousThematique`).then((res) => {
      setAllSousThematiques(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      setAllThematiques(res.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`https://back.r3mob.fr/publication/thematiques/${pubId}`)
      .then((res) => {
        setPublicationSousThematiques(res.data);
      });
  }, [pubId]);

  useEffect(() => {
    const selectedThematiquesIds = [];
    const selectedSousThematiquesIds = [];
    const groupedSousThematiques = {};

    publicationSousThematiques.forEach((publication) => {
      if (publication.SousThematique) {
        selectedSousThematiquesIds.push(publication.SousThematique.id);
        if (!selectedThematiquesIds.includes(publication.SousThematique.ThematiqueId)) {
          selectedThematiquesIds.push(publication.SousThematique.ThematiqueId);
        }
        const thematique = allThematiques.find(t => t.id === publication.SousThematique.ThematiqueId);
        if (thematique) {
          if (!groupedSousThematiques[thematique.nom]) {
            groupedSousThematiques[thematique.nom] = [];
          }
          groupedSousThematiques[thematique.nom].push(publication.SousThematique);
        }
      }

      if (publication.ThematiqueId) {
        if (!selectedThematiquesIds.includes(publication.ThematiqueId)) {
          selectedThematiquesIds.push(publication.ThematiqueId);
        }
      }
    });

    setSelectedThematiques(selectedThematiquesIds);
    setSelectedSousThematiques(selectedSousThematiquesIds);
    setSousThematiquesGrouped(groupedSousThematiques);
    setInitialSousThematiquesGrouped(groupedSousThematiques);
    setInitialSousThematiques(selectedSousThematiquesIds);
    setInitialThematiques(selectedThematiquesIds);
  }, [publicationSousThematiques, allThematiques]);

  /* const handleImageUpload = async (e) => {
    setSelectedImage(e.target.files[0]);
    if (!selectedImage) return; 
  }; */

  const handleResetChanges = () => {
    setNom(title);
    setUrl(pubUrl);
    /* setSelectedImage(null); */
    setSelectedThematiques(initialThematiques);
    setSelectedSousThematiques(initialSousThematiques);
    setSousThematiquesGrouped(initialSousThematiquesGrouped);
  };

  const handleSaveChanges = async () => {
    setIsSaveClicked(true); 
    if (!nom || nom === "") {
      return;
    }
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("url", url);
    formData.append("selectedThematiques", selectedThematiques.join(","));
    formData.append("selectedSousThematiques", selectedSousThematiques.join(","));
    /* if (selectedImage) formData.append("selectedImage", selectedImage); */
    formData.append("userId", authState.id);
    try {
      await axios.put(`https://back.r3mob.fr/createPublication/${pubId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  const handleThematiqueChange = (id) => {
    let updatedSelectedThematiques = [...selectedThematiques];
    if (updatedSelectedThematiques.includes(id)) {
      updatedSelectedThematiques = updatedSelectedThematiques.filter(
        (thematique) => thematique !== id
      );
    } else {
      updatedSelectedThematiques.push(id);
    }
    setSelectedThematiques(updatedSelectedThematiques);
    const groupedSousThematiques = updatedSelectedThematiques.reduce(
      (acc, thematiqueId) => {
        const filteredSousThematiques = allSousThematiques.filter(
          (sousThematique) => sousThematique.Thematique.id === thematiqueId
        );
        const thematiqueName = allThematiques.find(
          (thematique) => thematique.id === thematiqueId
        ).nom;
        if (filteredSousThematiques.length > 0) {
          acc[thematiqueName] = filteredSousThematiques;
        }
        return acc;
      },
      {}
    );
    setSousThematiquesGrouped(groupedSousThematiques);
  };

  const handleSousThematiqueChange = (id) => {
    if (selectedSousThematiques.includes(id)) {
      setSelectedSousThematiques(
        selectedSousThematiques.filter((st) => st !== id)
      );
    } else {
      setSelectedSousThematiques([...selectedSousThematiques, id]);
    }
  };

  /* const backgroundImage = selectedImage ? URL.createObjectURL(selectedImage) : image; */

  return (

    <PopupContainer>
      <PopupContent>

        <Title>Modifier la publication</Title>

        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          {isSaveClicked &&
            (nom === null || nom === "") && (
              <ErrorMessage>
                Le nom de la publication est obligatoire.
              </ErrorMessage>
            )}
          <Textarea
            rows="4"
            placeholder="Titre de la publication"
            value={nom}
            onChange={(e) => {
              setNom(e.target.value);
            }}
          />
          <Input
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            value={url}
            style={{ marginTop: "20px" }}
            type="text"
            placeholder="Url de la publication..."
          />
        </InputSection>

        <Label>Thématiques:</Label>
        <CheckboxContainer>
          {allThematiques.map((thematique) => (
            <CheckboxLabel key={thematique.id}>
              <CheckboxInput
                type="checkbox"
                checked={selectedThematiques.includes(thematique.id)}
                onChange={() => handleThematiqueChange(thematique.id)}
              />
              {thematique.nom}
            </CheckboxLabel>
          ))}
        </CheckboxContainer>

        {Object.keys(sousThematiquesGrouped).map((thematiqueName) => (
          <SousThematiqueGroup key={thematiqueName}>
            <Label>Sous-thématiques de {thematiqueName}:</Label>
            <CheckboxContainer>
              {sousThematiquesGrouped[thematiqueName].map((sousThematique) => (
                <CheckboxLabel key={sousThematique.id}>
                  <CheckboxInput
                    type="checkbox"
                    checked={selectedSousThematiques.includes(sousThematique.id)}
                    onChange={() => handleSousThematiqueChange(sousThematique.id)}
                  />
                  {sousThematique.nom}
                </CheckboxLabel>
              ))}
            </CheckboxContainer>
          </SousThematiqueGroup>
        ))}

        {/* <ImageSection>
          <ImageField src={backgroundImage} alt="Publication Image" />
          <UploadArea>
            <FileInputContainer>
              <FileInputLabel>
                Sélectionner une image
                <FileInput type="file" onChange={handleImageUpload} />
              </FileInputLabel>
            </FileInputContainer>
          </UploadArea>
        </ImageSection> */}


        <ButtonContainer>
          <ResetButton onClick={handleResetChanges}>Réinitialiser</ResetButton>
          <ConfirmButton onClick={handleSaveChanges}>Confirmer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
    </PopupContainer>

  );

};

export default EditPubPopup;
