import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

import { Logo, NavbarLink, NavbarStyle, LeftContainer,
  RightContainer, NavbarExtendedContainer, NavbarInnerContainer,
  NavbarLinkContainer, OpenLinksButton, NavbarLinkExtended,
  LoginLink, LoginLinkExtended, NavbarLinkList, LogoutLinkExtended } from "../styles/Navbar.style";
import "../styles/Navbar.css";

import LogoImg from "../assets/logo.jpg";
import UserImage from "../assets/icons-utilisateur.png";

function Navbar() {

  const { authState, setAuthState } = useContext(AuthContext);
  const [openLinks, setOpenLinks] = useState(false);
  const [access, setAccess] = useState(false);

  // Fonction de gestion d'événement pour les liens étendus
  const handleNavbarLinkClick = () => {
    console.log("handleNavbarLinkClick");
    setOpenLinks(false);
  };

  useEffect(() => {
    setAccess(authState.role);
  }, [authState]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false, role: "" });
    handleNavbarLinkClick();
  };

  return (

    <NavbarStyle extendnavbar={openLinks}>
      <NavbarInnerContainer>

        <LeftContainer>
          <Link to="/">
            {" "}
            <Logo src={LogoImg} alt="Logo"></Logo>
          </Link>
        </LeftContainer>

        <RightContainer>

          <NavbarLinkContainer>

          <NavbarLink to="/apropos"> À propos du réseau</NavbarLink>
          
          <div className="dropdown">
            <NavbarLinkList to="/projet">
              Projets <span className="dropdownbtn">&#8964;</span>
              <div className="dropdown-content">
                <NavbarLink to="/projet#projets">Projets R3MOB</NavbarLink>
                <NavbarLink to="/projet#aap">Appels à projets</NavbarLink>
              </div>
            </NavbarLinkList>
          </div>

          <NavbarLink to="/agenda">Agenda</NavbarLink>

          <div className="dropdown">
            <NavbarLinkList to="/ressources">
              Ressources <span className="dropdownbtn">&#8964;</span>
              <div className="dropdown-content">
                <NavbarLink to="/ressources#publications">
                  Publications
                </NavbarLink>
                <NavbarLink to="/ressources#videos">Vidéos</NavbarLink>
              </div>
            </NavbarLinkList>
          </div>

          <NavbarLink to="/stage&emploi">Stage & emploi</NavbarLink>

            {!authState.status ? (

              <>
                <LoginLink to="/login">Se connecter</LoginLink>
              </>

            ) : (

              <>

                <div className="dropdown">
                  <NavbarLinkList to="/annuaires">
                    Annuaires <span className="dropdownbtn">&#8964;</span>
                    <div className="dropdown-content">
                      <NavbarLink to="/chercheur">Acteurs R3MOB</NavbarLink>
                      <NavbarLink to="/laboratoire">
                        Laboratoires scientifiques
                      </NavbarLink>
                      <NavbarLink to="/universite">Universités</NavbarLink>
                      <NavbarLink to="/autreEtab">
                        Autres Etablissement
                      </NavbarLink>
                      <NavbarLink to="/partenaire">Partenaires</NavbarLink>
                    </div>
                  </NavbarLinkList>
                </div>

                <br />

                <div className="user-container dropdown" >
                  <img src={UserImage} alt="User" className="user-image" />
                  <span className="dropdownbtn-profile">&#8964;</span>
                  <div className="dropdown-content-profile">
                    <div className="dropdown-content-profile">
                      {authState.role === true && (
                        <>
                          <NavbarLink to="/uploadBase">
                            Actualiser la base de données
                          </NavbarLink>
                          <NavbarLink to="/updateEvent">
                            Actualiser les évènements
                          </NavbarLink>
                          <NavbarLink to="/admin">Admin</NavbarLink>
                          <NavbarLink to="/verifyUrl">
                            Vérifier les Urls
                          </NavbarLink>
                        </>
                      )}
                      <NavbarLink to="/profile">
                        {authState.username}
                      </NavbarLink>
                      <NavbarLink to="/" onClick={logout}>
                        Se déconnecter{" "}
                      </NavbarLink>
                    </div>
                  </div>
                </div>

              </>

            )}

            <OpenLinksButton onClick={() => {setOpenLinks(!openLinks);}}>
              {openLinks ? <>&#10005;</> : <>&#8801;</>}
            </OpenLinksButton>

          </NavbarLinkContainer>
        </RightContainer>

      </NavbarInnerContainer>

      {openLinks && (

        <NavbarExtendedContainer>

          <NavbarLinkExtended to="/apropos" onClick={handleNavbarLinkClick}>
            À propos du réseau
          </NavbarLinkExtended>

          <NavbarLinkExtended to="/projet" onClick={handleNavbarLinkClick}>
            Projets
          </NavbarLinkExtended>

          <NavbarLinkExtended to="/agenda" onClick={handleNavbarLinkClick}>
            Agenda
          </NavbarLinkExtended>

          <NavbarLinkExtended to="/ressources" onClick={handleNavbarLinkClick}>
            Ressources
          </NavbarLinkExtended>

          <NavbarLinkExtended to="/stagesEtEmploi" onClick={handleNavbarLinkClick}>
            Stage & emploi
          </NavbarLinkExtended>

          {!authState.status ? (

            <>
              <LoginLinkExtended to="/login" onClick={handleNavbarLinkClick}>Se connecter</LoginLinkExtended>
            </>
          
          ) : (

            <>

              <NavbarLinkExtended to="/annuaires" onClick={handleNavbarLinkClick}>
                      Annuaires
              </NavbarLinkExtended>

              {authState.role === true && (
                <>
                  <NavbarLinkExtended to="/uploadBase" onClick={handleNavbarLinkClick}>
                    Actualiser la base de données
                  </NavbarLinkExtended>
                  <NavbarLinkExtended to="/updateEvent" onClick={handleNavbarLinkClick}>
                    Actualiser les évènements
                  </NavbarLinkExtended>
                  <NavbarLinkExtended to="/admin" onClick={handleNavbarLinkClick}>
                    Admin
                  </NavbarLinkExtended>
                  <NavbarLinkExtended to="/verifyUrl" onClick={handleNavbarLinkClick}>
                    Vérifier les Urls
                  </NavbarLinkExtended>
                </>
              )}

              <NavbarLinkExtended to="/profile" onClick={handleNavbarLinkClick}>
                {authState.username}
              </NavbarLinkExtended>

              <LogoutLinkExtended to="/" onClick={logout}>
                Se déconnecter{" "}
              </LogoutLinkExtended>

            </>
          )}

        </NavbarExtendedContainer>

      )}

    </NavbarStyle>
  
  );

};

export default Navbar;
