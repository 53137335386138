import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import { Button, Table, Th, Td, ButtonDelete,
  ButtonEdit, TdActions, TableContainer
} from "../styles/UpdateEvent.style";

import EditVideoPopup from "./EditVideoPopup";
import AddVideoPopup from "./AddVideoPopup";


function UpdateVideosUrl() {

  const [videos, setVideos] = useState([])
  const [showEditVideoPopup, setShowEditVideoPopup] = useState(false);
  const [showAddVideoPopup, setShowAddVideoPopup] = useState(false);
  const [videoToEdit, setVideoToEdit] = useState({
      id: "",
      title: "",
      url: "",
  });

  useEffect(() => {
    axios.get("https://back.r3mob.fr/videos").then((response) => {
        setVideos(response.data);
    });
  }, []);

  function handleDeleteVideo(id) {
      axios.delete(`https://back.r3mob.fr/videos/${id}`).then((response) => {
          setVideos(
              videos.filter((video) => {
                  return video.id !== id;
              })
          );
      });
  }

  function handleEditVideo(video){
      setVideoToEdit(video);
      setShowEditVideoPopup(true);
  }

  function handleSavevideoChanges(video){
      axios.put(`https://back.r3mob.fr/videos/${video.id}`, {
          title: video.title,
          url: video.url,
      }).then((response) => {
          setVideos(
              videos.map((vid) => {
                  return video.id === vid.id ? response.data : vid;
              })
          );
      });
      setShowEditVideoPopup(false);
  }

  function handleClosevideoPopup(){
      setShowEditVideoPopup(false);
  }

  function handleCreateVideo(video){
      axios.post(`https://back.r3mob.fr/videos`, {
          title: video.title,
          url: video.url,
      }).then((response) => {
          setVideos([...videos, response.data]);
      });
      setShowAddVideoPopup(false);
  }

  function handleCloseVideoPopup(){
      setShowAddVideoPopup(false);
  }


  return (

    <>
      <TableContainer>
      
        <Button onClick={()=>{setShowAddVideoPopup(true);}}>
          Ajouter une vidéo
        </Button>
      
        <Table>

          <thead>
            <tr>
              <Th>Titre Vidéo</Th>
              <Th>Url Vidéo</Th>
              <Th>Actions</Th>
            </tr>
          </thead>

          <tbody>
            {videos.map((video) => (
              <tr key={video.id}>
                <Td>{video.title}</Td>
                <Td>{video.url}</Td>
                <Td
                  style={{
                    border: "1px solid #ccc",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <TdActions>
                    <ButtonEdit onClick={() => handleEditVideo(video)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </ButtonEdit>
                    <ButtonDelete onClick={() => handleDeleteVideo(video.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonDelete>
                  </TdActions>
                </Td>
              </tr>
            ))}
          </tbody>

        </Table>
      </TableContainer>

      {showEditVideoPopup && videoToEdit && (
        <EditVideoPopup
          video={videoToEdit}
          onSave={handleSavevideoChanges}
          onClose={handleClosevideoPopup}
        />
      )}
      {showAddVideoPopup && (
        <AddVideoPopup
          onSave={handleCreateVideo}
          onClose={handleCloseVideoPopup}
        />
      )}

    </>
    
  );

};

export default UpdateVideosUrl;
