import React from "react";

import { CardContainer, Name, Nombre, Image } from "../styles/CarteActeur.style";


const CarteActeur = ({ dataTestId="carte-acteur", name, nombre, image }) => {

  return (

    <CardContainer data-testid={dataTestId}>
      <Image src={image}></Image>
      <Nombre data-testid={`${dataTestId}-nombre`}>{nombre !== undefined ? nombre : '0'}</Nombre>
      <Name>{name}</Name>
    </CardContainer>

  );

};

export default CarteActeur;
