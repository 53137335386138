import axios from "axios";
import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, CloseButton, PopupContent,
  ConfirmButton, ButtonContainer, Input, Label, InputSection 
} from "../styles/EditEventPopup.style";

const ResetPasswordPopup = ({ onSave, onClose }) => {

  const [email, setEmail] = useState(null);

  const handleSaveChanges = () => {
    axios.post("https://back.r3mob.fr/auth/reset-password", {
      email: email,
    }).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
        onClose();
      } else {
        alert("Un email a été envoyé à l'adresse indiquée. Veuillez vérifier votre boîte de réception. Si vous ne recevez pas l'email, veuillez vérifier votre dossier de courrier indésirable.");
        onSave();
      }
    });
  };


  return (

    <PopupContainer>
      <PopupContent>

        <Title style={{color: "#000000"}}>Réinitialisation du mot de passe</Title>

        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          <Label>Email</Label>
          <p style={{color: "#000000", fontSize: "14px"}}>
            Saisissez l'adresse e-mail que vous avez utilisée pour vous enregistrer auprès de nous. 
            Nous vous enverrons un lien pour réinitialiser votre mot de passe.
          </p>
          <Input
            type="text"
            placeholder="Votre Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputSection>

        <ButtonContainer>
          <ConfirmButton onClick={handleSaveChanges}>Envoyer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
    </PopupContainer>

  );

};

export default ResetPasswordPopup;
