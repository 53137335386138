import React ,{ useState,useEffect }from 'react';

import { FaMapMarkerAlt,FaCalendarAlt } from "react-icons/fa";
import axios from 'axios';

import { PageLink, CardContainer, CardDate, CardContent,
  EventDescription, EventInfo, EventLocation, EventTitle
} from '../styles/EventCard.style';


const EventCard = ({ id, date, title, description, locationType, eventType, location, registrationLink, imageUrl, fallbackImageUrl }) => {
  
  const [playAnimation, setPlayAnimation] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    // Set playAnimation to true when the component mounts (initial animation)
    setPlayAnimation(true);
    
    // Return a function to set playAnimation to false when the component unmounts
    return () => {
      setPlayAnimation(false);
    };
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/event/image/${id}`).then((res) => {
      // //console.log(res.data);
      if (res.data && res.data !== null) {
        // Vérifiez que res.data n'est pas nul
        setImage(`data:image/png;base64,${res.data}`);
      } else {
        setImage(null);
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  
  const backgroundImage = image ? `${image}` : `${fallbackImageUrl}`;

  return (

    <PageLink to={`/event/${id}`}> 
    <CardContainer imageUrl={backgroundImage}  playAnimation={playAnimation} data-testid="card-container">
      <CardContent>

        <EventTitle>{title}</EventTitle>

        <EventDescription>{description}</EventDescription>

        <EventInfo>
          <EventLocation><FaMapMarkerAlt/> {location}</EventLocation>
          <CardDate><FaCalendarAlt/>{date}</CardDate>
        </EventInfo>

      </CardContent>
    </CardContainer>
    </PageLink>

  );

};


export default EventCard;
