import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import { Button, Table, Th, Td, TdActions, TableContainer, Validate} from "../styles/UpdateEvent.style";

function VerifyUrl() {

  const [urls, setUrls] = useState([]);
  const [modifiedLinks, setModifiedLinks] = useState({});
  //const [newLinks, setNewLinks] = useState([]);
  const { authState } = useContext(AuthContext);

  const handleLinkChange = (event, url) => {
    const { value } = event.target;
    setModifiedLinks((prevLinks) => ({ ...prevLinks, [url.id]: value }));
  };

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/urls`).then((res) => {
        setUrls(res.data);
    });
  }, []);

  const handleVerifyLinks = async () => {
    try {
      const response = await axios.get(`https://back.r3mob.fr/urls/verify`);
      setUrls(response.data.etabs);
      response.data.etabs.forEach((etab) => {
        handleStoreUrls(etab);
      });
    } catch (error) {
      console.error("Error fetching URLs:", error);
    }
  };

  const handleStoreUrls = async (etab) => {
    try {
      const newUrl = {
        idEtab: etab.id,
        nom: etab.nom,
        type: "etablissement", 
        url: etab.url, 
      };
      const response = await axios.post(`https://back.r3mob.fr/urls`, newUrl);
    } catch (error) {
      console.error("Error creating new URL:", error);
    }
  };

  const handleModifyClick = (url) => {
    const newLinkText = modifiedLinks[url.id] || url.url;
    axios
      .put(`https://back.r3mob.fr/etablissement/updateUrl/${url.id}`, {
        newUrl: newLinkText,
      })
      .then(() => {
        setModifiedLinks((prevLinks) => ({ ...prevLinks, [url.id]: "" }));
        setUrls((prevUrls) => { 
          return prevUrls.map((u) => {
            if (u.id === url.id) {
              return { ...u, url: newLinkText };
            }
            return u;
          });
        }
        );
      })
      .catch((error) => {
        console.error("Error updating URL:", error);
      });
  };


  return (

    authState.status==true && authState.role===true? (

      <div className="body">

        <div className="main" style={{marginTop: "100px"}}>

          <main>

            <TableContainer>

              <Button onClick={handleVerifyLinks}>Lancer une Vérification</Button>

              <Table>

                <thead>
                  <tr>
                    <Th>Etablissement</Th>
                    <Th>Url</Th>
                    <Th>Actions</Th>
                  </tr>
                </thead>

                <tbody>
                  {urls.map((url) => (

                    <tr key={url.id}>
                      <Td>{url.nom}</Td>
                      <Td>{url.url}</Td>
                      <Td>
                        <TdActions>
                          <input
                            type="text"
                            placeholder="Insérer le nouveau lien"
                            value={modifiedLinks[url.id] || ""}
                            onChange={(e) => handleLinkChange(e, url)}
                          />
                          <Validate onClick={() => handleModifyClick(url)}><FontAwesomeIcon icon={faCheck} /></Validate>
                        </TdActions>
                      </Td>
                    </tr>
                  ))}
                </tbody>

              </Table>

            </TableContainer>
            
          </main>

          <aside className="mobile">
            <ScrollButton />
          </aside>
        
        </div>

      </div>

    ) : (
      <Navigate to="/404" />
    )
  );
}

export default VerifyUrl;
