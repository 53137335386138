import styled from "styled-components";
import Colors from "../styles/Colors";


export const FooterContainer = styled.footer`
  /* text-align: center; */
  background: ${Colors.color1};
  padding-top: 10px;
  width: 100vw;
`;

export const SocialIcons = styled.div`
  margin-bottom: 10px;
  text-align: center;
  a {
    margin: 0 10px;
    text-decoration: none;
    font-size: 30px;
    color: ${Colors.color5};

    &:hover {
      color: ${Colors.color2};
    }
    @media (max-width: 768px) {
    font-size: 20px;
  }
  }
`;

export const CopyrightText = styled.p`
  margin: 0;
  font-size: 1.3rem;
  text-align: center;
  color: ${Colors.color5};
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;