import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, CloseButton, PopupContent, ConfirmButton,
  ButtonContainer, ImageSection, UploadArea, FileInput, FileInputLabel,
  FileInputContainer, ImageField } from "../styles/CreatePubPopup.style";

import { AuthContext } from "../helpers/AuthContext";


const ChangerPhotoPopup = ({ chercheurId, onSave, onClose }) => {

  const { authState } = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [chercheur, setChercheur] = useState({});

  useEffect(() => {
    axios
      .get(`https://back.r3mob.fr/detailsChercheur/${chercheurId}`)
      .then((res) => {
        if (res.data !== null) {
          setChercheur(res.data);
        } else {
          window.location.replace("/404");
        }
      });
  }, [chercheurId]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(file);
    }
  };

  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append("selectedImage", selectedImage);
    formData.append("userId", authState.id);
    onSave(formData);
  };

  const fallbackImageUrl = "default_user.png";
  const imageUrl = selectedImage ? URL.createObjectURL(selectedImage) : null;
  const chercheurImageUrl = chercheur.imageData ? `data:image/png;base64,${chercheur.imageData}` : null;
  const backgroundImage = imageUrl || chercheurImageUrl || fallbackImageUrl;


  return (

    <PopupContainer>
      <PopupContent>

        <Title>Modifier votre photo de profile</Title>

        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <ImageSection>
          <ImageField src={backgroundImage} alt="Profile Image" />
          <UploadArea>
            <FileInputContainer>
              <FileInputLabel>
                Sélectionner une image
                <FileInput type="file" onChange={handleImageUpload} />
              </FileInputLabel>
            </FileInputContainer>
          </UploadArea>
        </ImageSection>

        <ButtonContainer>
          <ConfirmButton onClick={handleSaveChanges}>Confirmer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
    </PopupContainer>

  );

};


export default ChangerPhotoPopup;
