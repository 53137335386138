import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, PopupContent, ConfirmButton,
  LeaveButton, ButtonContainer, CloseButton, } from "../styles/CreatePubPopup.style";

const ConfirmationPopup = ({onSave, onClose }) => {

  return (

    <PopupContainer>
      <PopupContent>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Title>Voulez vous vraiment suprimer cette publication?</Title>
        <ButtonContainer>
          <LeaveButton onClick={onClose}>Annuler</LeaveButton>
          <ConfirmButton onClick={onSave}>Confirmer</ConfirmButton>
        </ButtonContainer>
      </PopupContent>
    </PopupContainer>

  );
};

export default ConfirmationPopup;
