import styled from "styled-components";
import Colors from "../styles/Colors";

export const Span = styled.span`
  margin-left: 5px;
  color: var(--color1);
  font-size: small;
  font-style: italic;
  text-align: center;
  @media (max-width: 1966px) {
    font-size: x-small;
  }
  @media (max-width: 1465px) {
    font-size: 8px;
  }
  @media (max-width: 1210px) {
    font-size: 6px;
  }

`;
export const CardContentTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  @media (max-width: 1465px) {
    height: 55%;
  }
  @media (max-width: 1210px) {
    height: 70%;
  }

`;

export const CardContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Username = styled.a`
  margin-left: 10px;
  color: ${Colors.color1};
  font-size: medium;
  font-style: italic;
  @media (max-width: 1966px) {
    font-size: x-small;
  }
  @media (max-width: 1465px) {
    font-size: xx-small;
  }
  @media (max-width: 1210px) {
    font-size: 6px;
  }
  @media (max-width: 990px) {
    font-size: xx-small;
  }
`;
export const ThematiquesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5%;
  @media (max-width: 1329px) {
    margin-bottom: 1px;
  }
  gap: 5px;

`;

export const CardImage = styled.img`
  background-position: center;
  width: 100%;
  height: 90%;
  float: left;
  float: inline-end;
  border-radius: 20px;
`;

export const CardContainer = styled.div`
  margin-top: 10px; /* Ajoutez un espace en haut */
  margin-bottom: 20px; /* Ajoutez un espace en bas */
  background-color: ${Colors.color6};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-radius: 20px;
  padding-bottom: 20px;
  animation: ${(props) =>
    props.playAnimation ? "rotate 0.5s ease, zoom 0.5s ease" : "none"};
  @keyframes rotate {
    from {
      transform: rotateY(30deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  &:hover {
    box-shadow: 0 4px 8px rgba(10, 10, 10, 0.5);
  }
  @media (max-width: 1966px) {
    width: 400px;
    height: 250px;
  }
   @media (max-width: 1465px) {
    width: 350px;
    height: 200px;
  }
  @media (max-width: 1329px) {
    width: 300px;
    height: 170px;
  }
  @media (max-width: 1210px) {
    width: 250px;
    height: 150px;
  }
  @media (max-width: 990px) {
    width: 300px;
    height: 170px;
  }
  /*
  @media (max-width: 1075px) {
    width: 160px;
    height: 120px;
  }
  @media (max-width: 866px) {
    width: 250px;
    height: 150px;
  } */
`;

export const CardContent = styled.div`
  position: relative; /* Add this to create a new stacking context */
  z-index: 1; /* Position the content above the gradient layer */
  width: 50%; /* Définit la largeur à 50% pour occuper la moitié droite de la carte */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ajuste la hauteur pour occuper la hauteur complète de la carte */
  padding: 10px; /* Ajoute une marge intérieure pour espacer le contenu du bord de l'image */
`;

export const EventTitle = styled.h2`
  font-size: 20px;
  color: var(--color1);
  font-weight: bold;
  line-height: 2.5rem;
  justify-content: center;
  @media (max-width: 1966px) {
    line-height: 1.5rem;
    font-size: 14px;
  }
  @media (max-width: 1465px) {
    line-height: 1rem;
    font-size: 12px;
  }
  @media (max-width: 1329px) {
    font-size: 11px;
  }
  @media (max-width: 1210px) {
    line-height: 0.8rem;
    font-size: 9px;
  }
  @media (max-width: 990px) {
    font-size: 11px;
  }
`;