
import React from 'react';
import { Ellipsis, PageButton } from '../styles/Defiler.style';


const Defiler = ({currentPage, totalPages, handlePageClick }) => {

  const handlePreviousPage = () => {
      if (currentPage > 1) {
        handlePageClick(currentPage - 1);
      }
  };
    
  const handleNextPage = () => {
      if (currentPage < totalPages) {
        handlePageClick(currentPage + 1);
      }
  };

  const maxPageButtons = 3;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  
  let startPage = Math.max(currentPage - halfMaxButtons, 1);
  let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
  if (endPage - startPage + 1 < maxPageButtons) {
    startPage = Math.max(endPage - maxPageButtons + 1, 1);
  }


  return (

    <div className="Défiler" style={{textAlign : "center"}}>

        {/* Bouton "Précédent" */}
        {totalPages > 3 && startPage > 1 && (
            <PageButton onClick={handlePreviousPage}>
              &larr;
            </PageButton>
        )}

        {/* Display first page */}
        {startPage > 1 && (
            <PageButton onClick={() => handlePageClick(1)}>
              1
            </PageButton>
        )}

        {/* Display ellipsis if needed */}
        {startPage > 2 && totalPages > 3 && <Ellipsis>...</Ellipsis>}

        {/* Display page buttons within the range */}
        {totalPages > 1 && Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <PageButton
              className={`${startPage + index === currentPage ? "active" : ""}`}
              key={startPage + index}
              onClick={() => handlePageClick(startPage + index)}
            >
              {startPage + index}
            </PageButton>
        ))}

        {/* Display ellipsis if needed */}
        {endPage < totalPages - 1 && totalPages > 3 && <Ellipsis>...</Ellipsis>}

        {/* Display last page */}
        {endPage < totalPages && (
            <PageButton onClick={() => handlePageClick(totalPages)}>
              {totalPages}
            </PageButton>
        )}

        {/* Bouton "Suivant" */}
        {endPage < totalPages && totalPages > 3 && (
            <PageButton onClick={handleNextPage}>
              &rarr;
            </PageButton>
        )}

    </div>

  );

};

export default Defiler;
