import React,{useEffect,useState} from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

import "../styles/Carte.css";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import Footer from "../components/Footer";

function Carte() {

  const [linkCartographie, setLinkCartographie] = useState({});
  const { authState } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Effectuez vos opérations de vérification d'URL ou autres ici
    // Une fois les opérations terminées, définissez isLoading sur false
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Exemple de délai de chargement (2 secondes)
  }, []);
  
  useEffect(() => {
    axios.get("https://back.r3mob.fr/infos/cartographie").then((response) => {
      setLinkCartographie(response.data);
    });
  }, []);

	if (isLoading) {
    return <PropagateLoader cssOverride={{
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center",
      "height": "100vh"
    }}
      color="#36d7b7" />
  }

  return (

    authState.status==true ? (

    <div className="body">

      <div className="main">

          <div id="iframe-container">
            <iframe src={linkCartographie}></iframe>
          </div>

        <aside className="mobile">
          <ScrollButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

    ) : (
      <Navigate to="/login" />
    )

  );
}

export default Carte;
