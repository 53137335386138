import styled from "styled-components";
import Colors from "../styles/Colors";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
`;

export const Title = styled.h1`
  margin: 0; 
  color: ${Colors.color2};
`;