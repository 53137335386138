import styled from "styled-components";
import Colors from "../styles/Colors";

export const NavBar = styled.div`
  display: flex;
  padding: 10px;
  align-self: center;
  align-items: center;
`;

export const ChooseButton = styled.button`
  background-color: ${(props) =>
    props.active ? Colors.color2 : Colors.color5};
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 0;
  margin-left: 0;
`;

export const NavBarAnime = styled.div`
    margin-top: 100px;
    justify-content: center;
    display: flex;
    flex-direction: column;
`; 