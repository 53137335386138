import React, { useState, useEffect } from "react";

import {  CardImage, CardContainer, CardContent, 
  StageInfos, StageTitle, EventLocation } from "../styles/CarteStage.style";

  
const CarteStage = ({ id, title, disponible, link, url, fallbackUrl, imageUrl }) => {

  const [playAnimation, setPlayAnimation] = useState(false);

  const formattedUrl =
    url && (url.startsWith("http://") || url.startsWith("https://"))
      ? url
      : `http://${url}`;

  useEffect(() => {
    setPlayAnimation(true);
    return () => {
      setPlayAnimation(false);
    };
  }, []);  

  return (

    <a
        href={url && url !== "" ? formattedUrl : undefined}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >

    <CardContainer playAnimation={playAnimation}>

        <CardImage src={imageUrl} fallbackUrl={fallbackUrl} />

        <CardContent>

          <StageTitle>{title}</StageTitle>

          <StageInfos>
            <EventLocation isDisponible={disponible===true}>{disponible ? "Disponible " : "Non disponible"}</EventLocation>
          </StageInfos>

        </CardContent>

    </CardContainer>
    
    </a>

  );

};


export default CarteStage;
