import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardsContainer = styled.div`
  position: relative;
  transition: filter 0.3s;
  border-radius: 8px;
  display: flex;
  margin: 5%;
  align-items: center;
  justify-content: flex-start; 
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  &:hover {
    filter: none;
  }
  animation: rotate 0.5s ease, zoom 0.5s ease; /* Ajout de l'animation de rotation */

  @keyframes rotate {
    from {
      transform: rotateY(30deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const PageLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;