import styled from 'styled-components';

export const ProjetGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding-top: 15px;
  
`;

export const ProjetCardContainer = styled.div`
  @media (min-width: 2189px) {
    height: auto;
    width: auto;
    }
`;