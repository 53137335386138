import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import { AuthContext } from "../helpers/AuthContext";

import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import BordeauxMap from "../components/BordeauxMap";
import ListeActeur from "../components/ListeActeur";

import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { MainContainer, Title } from "../styles/Annuaire.style";

function Annuaire() {

  const { authState } = useContext(AuthContext);
 
  return (
    authState.status==true ? (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle>{"> "} Annuaire</HeaderLinkStyle>
      </HeaderContent>
      </header>
      
      <div className="main">
        
        <MainContainer>

          <Title>R3MOB en quelques chiffres ...</Title>

          <ListeActeur />

          <BordeauxMap />

        </MainContainer>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

    ):(<Navigate to="/login" />)
  );
}

export default Annuaire;
