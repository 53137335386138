import React, { useState, useEffect } from "react";
import { FaUniversity } from "react-icons/fa";
import axios from "axios";

import ThematiqueIcon from "../components/ThematiqueIcon";
import Thematiques from "../components/Thematiques";

import { Span, CardContentTop, CardContentBottom, CardContent,
  Username, ThematiquesContainer, CardImage, CardContainer, 
  EventTitle } from "../styles/CarteProjet.style";

  
const CarteProjet = ({id, title, url, link, fallbackUrl, imageUrl, etablissements, enjeux}) => {

  const [playAnimation, setPlayAnimation] = useState(false);
  const [publicationSousThematiques, setPublicationSousThematiques] = useState([]);
  const [allThematique, setAllThematique] = useState([]);
  const [extractedThematiques, setExtractedThematiques] = useState([]);

  const formattedUrl =
    url && (url.startsWith("http://") || url.startsWith("https://"))
      ? url
      : `http://${url}`;

  useEffect(() => {
    setPlayAnimation(true);
    return () => {
      setPlayAnimation(false);
    };
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/projet/thematiques/${id}`).then((res) => {
      if (res.data.error) {
        // console.log(res.data.error);
      } else {
        setPublicationSousThematiques(res.data);
      }
    });
  }, [id]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      if (res.data.error) {
        // console.log(res.data.error);
      } else {
        setAllThematique(res.data);
      }
    });
  }, []);

  useEffect(() => {
    // Define an async function to fetch the thematic data
    const fetchThematiqueData = async () => {
      const thematicData = [];
      for (const publication of publicationSousThematiques) {
        const thematicId = publication.SousThematiqueId!==null ? publication.SousThematique.ThematiqueId : null;
        const thematic = allThematique.find(
          (thematique) => thematique.id === thematicId
        );
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex(
            (data) => data.thematique && data.thematique.nom === thematic.nom
          );
          if (thematiqueIndex !== -1) {
            // If the thematique already exists, update the sousThematique field
            thematicData[thematiqueIndex].sousThematique.push(
              " , ",
              publication.SousThematique.nom
            );
          } else {
            // If the thematique doesn't exist, add it to the array
            const them = Thematiques.find(
              (thematique) => thematique.nom === thematic.nom
            );
            thematicData.push({
              thematique: them,
              sousThematique: [publication.SousThematique.nom],
            });
          }
        }
      }
      //Extraire les Thematiques 
      for (const publication of publicationSousThematiques) {
        const thematicId = publication.ThematiqueId ? publication.ThematiqueId : null;
        if(!thematicId) continue;
        const thematic = allThematique.find((thematique) => thematique.id === thematicId);
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex((data) => data.thematique && data.thematique.nom === thematic.nom);
          // If the thematique doesn't exist, add it to the array
          if (thematiqueIndex === -1) {
            const them = Thematiques.find((thematique) => thematique.nom === thematic.nom);
            thematicData.push({ thematique: them, sousThematique: [them.nom] });
          }
        }
      }
      setExtractedThematiques(thematicData);
    };
    fetchThematiqueData();
  }, [publicationSousThematiques, allThematique]);


  return (

    <a
        href={url && url !== "" ? formattedUrl : undefined}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >

    <CardContainer playAnimation={playAnimation}>

      <CardContent>

        <CardContentTop>
          <ThematiquesContainer>
            {extractedThematiques.map((publicationSousThematiques) => ( 
              publicationSousThematiques.thematique && (
              <ThematiqueIcon
                key={publicationSousThematiques.thematique.nom}
                icon={
                  "../thematiques/" + publicationSousThematiques.thematique.icon
                }
                backgroundColor={
                  publicationSousThematiques.thematique.backgroundColor
                }
                subThematiques={publicationSousThematiques.sousThematique}
              />
            )))}
          </ThematiquesContainer>
          <EventTitle>{title}</EventTitle>
        </CardContentTop>

        <CardContentBottom>
          {etablissements.map((etablissement) => (
            <Username key={etablissement}>
              <FaUniversity />
              <Span>{etablissement}</Span>
            </Username>
          ))}
        </CardContentBottom>

      </CardContent>

      <CardContent>

        <CardImage src={imageUrl} fallbackUrl={fallbackUrl} />
        
        {enjeux && enjeux.length > 0 && (
          <Span>
            Enjeux :{" "}
            {enjeux.map((enjeu, index) => (
              <React.Fragment key={enjeu.id}>
                {enjeu.Enjeux.nom}
                {index < enjeux.length - 1 && " / "}
              </React.Fragment>
            ))}
          </Span>
        )}

      </CardContent>

    </CardContainer>
    
    </a>

  );
};


export default CarteProjet;
