import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import axios from "axios";

import { FooterContainer, SocialIcons, CopyrightText
} from "../styles/Footer.style";


function Footer() {

  const [links, setLinks] = useState({});

  useEffect(() => {
    axios.get("https://back.r3mob.fr/infos/reseauxSociaux").then((response) => {
      setLinks(response.data);
    }).catch((error) => {
      console.error(error);
    });
  }, []);


  return (

    <FooterContainer>

      <SocialIcons>
        {links.Facebook && (
          <a href={links.Facebook} target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
        )}
        {links.Instagram && (
        <a href={links.Instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram/>
        </a>
        )}
        {links.Twitter && (
          <a href={links.Twitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        )}
        {links.LinkedIn && (
          <a href={links.LinkedIn} target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        )}
      </SocialIcons>

      <CopyrightText data-testid="copyright-text">
        &copy; {new Date().getFullYear()} R3MOB - Réseau régional de recherche
        sur les nouvelles mobilités.
      </CopyrightText>

    </FooterContainer>

  );

};

export default Footer;
