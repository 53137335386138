import React, { useState, useContext } from "react";
import { AuthContext } from "../helpers/AuthContext";
import { Navigate } from "react-router-dom";

import UpdateActeurCounts from "../components/UpdateActeurCounts";
import PublicationsUrls from "../components/PublicationsUrls";
import UpdatePublicInfos from "../components/UpdatePublicInfos";
import UpdateVideosUrls from "../components/UpdateVideosUrls";
import UpdateEquipeReseau from "../components/UpdateEquipeReseau";
import ScrollBttn from "../components/ScrollButton";

import { NavBar, NavBarAnime, ChooseButton } from "../styles/Admin.style";

function Admin() {

  const [selectedComponent, setSelectedComponent] = useState("Acteurs");
  const { authState } = useContext(AuthContext);
  
  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Acteurs":
        return <UpdateActeurCounts />;
      case "PublicInfos":
        return <UpdatePublicInfos />
      case "PubUrls":
       return <PublicationsUrls />
      case "Videos":
        return <UpdateVideosUrls />;
      case "Equipe":
        return <UpdateEquipeReseau />;
      default:
       return <UpdateActeurCounts />;
    }
  };

  return (
    authState.status==true && authState.role===true? (
    <>
    <NavBarAnime>
      <NavBar>
        <ChooseButton onClick={() => setSelectedComponent("Acteurs")} active={selectedComponent === "Acteurs"}>
          Nombres Acteurs
        </ChooseButton>
        <ChooseButton onClick={() => setSelectedComponent("PublicInfos")} active={selectedComponent === "PublicInfos"}>
          Informations publiques
        </ChooseButton>
        <ChooseButton onClick={() => setSelectedComponent("PubUrls")} active={selectedComponent === "PubUrls"}>
          Liens des publications
        </ChooseButton>
        <ChooseButton onClick={() => setSelectedComponent("Videos")} active={selectedComponent === "Videos"}>
          Vidéos R3MOB
        </ChooseButton>
        <ChooseButton onClick={() => setSelectedComponent("Equipe")} active={selectedComponent === "Equipe"}>
          Equipe du réseau
        </ChooseButton>
      </NavBar>
      {renderSelectedComponent()}
    </NavBarAnime>

    <aside className="mobile">
    <ScrollBttn />
    </aside>
    </>
    ):(<Navigate to="/404" replace={true} />)
  );
}

export default Admin;
