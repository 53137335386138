import Colors from "../styles/Colors";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Button = styled.button`
  width: fit-content;
  min-width: 230px;
  height: fit-content;
  margin: 10px;
  border: none;
  background-color: ${Colors.color2};
  color: white;
  border-radius: 8px;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 15px 5px 15px 5px;
  &:hover {
    background-color: ${Colors.color1};
  }
`;