import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, CloseButton, PopupContent,
  LeaveButton, ButtonContainer, StyledText } from "../styles/CreatePubPopup.style";

const SendEmailConfirmationPopup = ({ onClose }) => {

    return (

      <PopupContainer>
        <PopupContent>

          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>

          <Title>Un email vous a été envoyé. Veuillez consulter votre boîte de réception.</Title>

          <StyledText>
            Si vous ne trouvez pas l'email dans votre boîte de réception, veuillez vérifier votre dossier de spam.
            Si vous avez encore des problèmes, veuillez nous contacter à l'adresse suivante :{" "}
            <a href="mailto:cedrik.ferrero@bordeaux-inp.fr">cedrik.ferrero@bordeaux-inp.fr</a>.
          </StyledText>

          <ButtonContainer>
            <LeaveButton onClick={onClose}>Quitter</LeaveButton>
          </ButtonContainer>

        </PopupContent>
      </PopupContainer>

    );
  };
  
  export default SendEmailConfirmationPopup;
  
