import styled from 'styled-components';
import Colors from './Colors.js';

export const CardContainer = styled.div`
  width: 300px;
  height: 300px;
  background-color: ${Colors.color4};
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  transition: filter 0.3s;
  text-align: center;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 4px 8px rgba(232, 67, 30, 0.5);
    
  }
  animation: rotate 0.5s ease, zoom 0.5s ease;  
  @media (max-width: 2230px) {
    width: 200px;
    height: 200px;

  }
  @keyframes rotate {
    from {
      transform: rotateY(30deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }

`;

export const Name = styled.h1`
  margin-top: 80;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${Colors.color3};
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 2230px) {
    font-size: 1.2rem;
  }
`;
export const Nombre = styled.h1`
  margin-top: 80;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${Colors.color3};
  @media (max-width: 2230px) {
    font-size: 1.2rem;
  }
`;
export const Image = styled.img`
  margin-top: 10px;
  width: 50%;
  height: 50%;
  object-fit: cover;
  top: 0;
  left: 0;
  
  @media (max-width: 2230px) {
    width: 40%;
    height: 40%;
  }
`;