import styled from "styled-components";
import Colors from "./Colors";
import { FaSearch, FaCalendarAlt, FaFilter } from "react-icons/fa";

export const SousThematiqueGroup = styled.div`
  margin-bottom: 20px;
  color: ${Colors.color3};
`;

export const Label = styled.label`
  color: ${Colors.color6};
  font-size: 16px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: right;
  font-weight: bold;
  width: 100%;
`;

export const Horizontal = styled.hr`
  background-color: ${Colors.color6};
  border: 0;
  width: 7vw;
  margin-right: 10px;
  display: block;

  @media (max-width: 990px) {
    width: 75vw; 
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CheckboxLabel = styled.label`
  margin: 5px 10px;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  align-items: center;
`;

export const CheckboxInput = styled.input`
  margin-right: 10px;
`;


export const FilterStyle = styled.div`
  margin-top: 30%;
  background-color: ${Colors.color4};
  border-radius: 10px; /* Bordure arrondie */
  padding: 15px; /* Petit padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

export const FilterStyleEquipe = styled.div`
  margin-top: 5%;
  background-color: ${Colors.color4};
  border-radius: 10px; /* Bordure arrondie */
  padding: 15px; /* Petit padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 14px;
  width: 100%; /* Uniformiser la largeur */
`;

export const InputSectionEquipe = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%; /* Uniformiser la largeur */
`;

export const Input = styled.input`
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%; /* Uniformiser la largeur */
  box-sizing: border-box; /* Inclure le padding dans la largeur totale */
  @media (max-width: 1075px) {
    font-size: 10px;
    width: 100%;
  }
`;

export const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%; /* Uniformiser la largeur */
  margin-bottom: 20px;
  background-color: white;
  color: gray;
  box-sizing: border-box; /* Inclure le padding dans la largeur totale */
  @media (max-width: 1075px) {
    font-size: 10px;
  }
`;

export const SelectOption = styled.option`
  font-size: 0.8rem;
  font-weight: bold;

  @media (max-width: 950px) {
    font-size: 0.6rem;
  }
  @media (max-width: 990px) {
    font-size: 0.6rem;
  }
`;

export const StyledSearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 16px;
`;

export const StyledCalendarSearch = styled(FaCalendarAlt)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: gray;
  cursor: pointer;
`;

export const StyledFilterSearch = styled(FaFilter)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #aaa;
`;

export const DatePickerContainer = styled.div`
  position: relative;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%; /* Uniformiser la largeur */

  @media (max-width: 1075px) {
    font-size: 10px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1075px) {
      font-size: 10px;
    }
  }
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box; /* Inclure le padding dans la largeur totale */
    @media (max-width: 1075px) {
      font-size: 10px;
    }
  }
  .react-datepicker__clear-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.7;
    @media (max-width: 1075px) {
      font-size: 10px;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const HorizontalLine = styled.hr`
  background-color: ${Colors.color6};
  border: 0;
  width: 11vw;
  margin-left: 10px;
  display: block;
  @media (max-width: 990px) {
    width: 84vw;
  }
`;

export const ReinitialiserButton = styled.button`
  margin-bottom: 30px;
  background-color: ${Colors.color2};
  color: ${Colors.color3};
  cursor: pointer;
  &:hover{
  background-color: ${Colors.color6};
  color: ${Colors.color2};
  }
`;

export const FilterTitle = styled.p`
  color: ${Colors.color6};
  font-size: 16px;
  margin-bottom: 25px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: left;
  font-weight: bold;
  width: 100%;
`;
