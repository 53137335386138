import React, { useState, useEffect } from "react";
import axios from "axios";

import CarteActeur from "../components/CarteActeur";
import { CardsContainer, PageLink } from "../styles/ListeActeur.style";

function ListeActeur() {

    const [counts, setCounts] = useState({
        ActeurR3MOB: 0,
        LaboratoireScientifique: 0,
        Universite: 0,
        AutreEtab: 0,
        Partenaire: 0
      });
     
    useEffect(() => {
        axios.get('https://back.r3mob.fr/annuaire/acteurCount').then((response) => {
          setCounts(response.data);
        }).catch((error) => {
          console.error(error);
        });
    }, []);

    return (
        <CardsContainer>

            <PageLink to="/chercheur">
              <CarteActeur
                dataTestId = "chercheur"
                image="Acteurs/Chercheurs.png"
                name="Acteurs R3MOB"
                nombre={counts.Acteur_R3MOB}
              />
            </PageLink>

            <PageLink to="/laboratoire">
              <CarteActeur
                dataTestId = "laboratoire"
                image="Acteurs/Laboratoire.png"
                name="Laboratoires scientifiques"
                nombre={counts.Laboratoire_Scientifique}
              />
            </PageLink>

            <PageLink to="/universite">
              <CarteActeur
                dataTestId = "universite"
                image="Acteurs/Université.png"
                name="Universités"
                nombre={counts.Universite}
              />
            </PageLink>

            <PageLink to="/autreEtab">
              <CarteActeur
                dataTestId = "autre-etab"
                image="Acteurs/Autre_Etablissement.png"
                name="Autres établissements"
                nombre={counts.Autre_Etablissement}
              />
            </PageLink>

            <PageLink to="/partenaire">
              <CarteActeur
                dataTestId = "partenaire"
                image="Acteurs/Partenaire.png"
                name="Partenaires"
                nombre={counts.Partenaire}
              />
            </PageLink>

        </CardsContainer>
    );
    

};

export default ListeActeur;
