import React, { useState, useEffect } from "react";
import axios from "axios";

import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import CartePerso from "../components/CartePerso";
import EventCard from "../components/EventCard";
import CartePublication from "../components/CartePublication";
import CarteProjet from "../components/CarteProjet";

import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { SecondaryTitle, PublicationCardContainer, PageLink, 
  PublicationGrid, EventGrid, EventCardContainer, PersoGrid,
  PersoCardContainer, CardContainer, TextContainer, Title,
  Paragraph, StyledSpan, ImageSelectionButton, ImageSelectionButtons,
  EnSavoirPlus, CenterContainer } from "../styles/Home.style";
import {ProjetCardContainer, ProjetGrid} from "../styles/Projet.style";


function Home() {

  const [currentImage, setCurrentImage] = useState(1);
  const [pilotes, setPilotes] = useState([]);
  const [etablissements, setEtablissements] = useState([]);
  const [events, setEvents] = useState([]);
  const [publications, setPublications] = useState([]);
  const [projets, setProjets] = useState([]);

  const images = [
    {
      src: "img_accueil1.jpg",
      title: "Réseau régional de recherche mobilités",
      span: "R3MOB",
      text: " vise à favoriser la collaboration entre porteurs et chercheurs spécialisés dans l'énergie ou des sujets connexes, afin de trouver des solutions pour améliorer les transports et leur système d'alimentation.",
    },
    {
      src: "img_accueil2.jpg",
      title: "Objectifs du réseau",
      span: "",
      text: "Le réseau R3MOB a pour objectif de :",
      elem1:
        "Coordonner les forces académiques et les projets sur le thème des nouvelles mobilités",
      elem2:
        "Développer des coopérations et des coordinations concrètes sur le long terme entre académiques et entreprises de la filière aménagement, mobilité, transport",
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/perso/equipe`).then((res) => {
      setPilotes(res.data);
    });
    axios.get(`https://back.r3mob.fr/etablissement`).then((res) => {
      setEtablissements(res.data);
    });
    axios.get(`https://back.r3mob.fr/event/3recent`).then((res) => {
      setEvents(res.data);
    });
    axios.get(`https://back.r3mob.fr/publication/3recent`).then((res) => {
      setPublications(res.data);
    });
    axios.get(`https://back.r3mob.fr/projet/3recent`).then((res) => {
      setProjets(res.data);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Fonction pour changer l'image manuellement
  const handleChangeImage = (index) => {
    setCurrentImage(index);
  };


  return (

    <div className="body">

      <HeaderContent>
        <HeaderLinkStyle>{"> "}Accueil</HeaderLinkStyle>
      </HeaderContent>
      
      <div>
        <CardContainer
          imageUrl={images[currentImage - 1].src}
          alt={`Image ${currentImage}`}
          playAnimation={true} >
          <TextContainer>
            <a href="/Apropos" style={{textDecoration: 'none', color: 'inherit'}}>
            <Title>{images[currentImage - 1].title}</Title>
            <Paragraph>          
              {images[currentImage - 1].span && (
                <StyledSpan>{images[currentImage - 1].span}</StyledSpan>
              )}
              {images[currentImage - 1].text}
              {images[currentImage - 1].elem1 &&
                images[currentImage - 1].elem2 && (
                  <ul>
                    <li>{images[currentImage - 1].elem1}</li>
                    <li>{images[currentImage - 1].elem2}</li>
                  </ul>
                )}
            </Paragraph>
            </a>
          </TextContainer>
          <ImageSelectionButtons>
            {images.map((image, index) => (
              <ImageSelectionButton
                key={index}
                isSelected={currentImage === index + 1}
                onClick={() => handleChangeImage(index + 1)}
              />
            ))}
          </ImageSelectionButtons>
        </CardContainer>
      </div>
      
      <div className="main">

        <main>

          {pilotes.length > 0 && (
            <>
            <SecondaryTitle>L'équipe du réseau </SecondaryTitle>
            <PersoGrid>
              {pilotes.map((value, key) => {
                const etablissementNames = value.Chercheur_etabs.map(
                  (chercheurEtab) => {
                    const etablissement = etablissements.find(
                    (etab) => etab.id === chercheurEtab.EtablissementId );
                    return etablissement ? etablissement.nom : "Pas indiqué";
                  }
                );
                return (
                  <PersoCardContainer key={key}>
                    <CartePerso
                      id={value.id}
                      name={value.username}
                      email={value.email ? value.email : "Pas indiqué"}
                      phone={value.phone ? value.phone : "Pas indiqué"}
                      address={etablissementNames.join(", ")}
                      imageData={value.imageData}
                    />
                  </PersoCardContainer>
                );
              })} 
            </PersoGrid>
            </>
          )}

          {events.length > 0 && (
            <>
            <SecondaryTitle>Les futurs événements </SecondaryTitle>
            <EventGrid>
              {events.map((value, key) => {
                return (
                  <EventCardContainer key={key}>
                    <EventCard
                      id={value.id}
                      date={formatDate(value.startDateTime)}
                      title={value.nom}
                      locationType={value.locationType}
                      eventType={value.eventType}
                      location={
                        value.locationType === "Visio"
                          ? "En ligne"
                          : value.location }
                      registrationLink={value.lienInscription}
                      imageUrl={`events_imgs/${value.id}.jpg`}
                      fallbackImageUrl={"events_imgs/event_default.jpg"}
                    />
                  </EventCardContainer>
                );
              })}
            </EventGrid>
            <CenterContainer>
            <PageLink to="/agenda">
              <EnSavoirPlus>Voir tous les évènements</EnSavoirPlus>
            </PageLink>
            </CenterContainer>
            </>
          )}
          
          {publications.length > 0 && (
            <>
            <SecondaryTitle>Les publications récentes</SecondaryTitle>
            <PublicationGrid>
              {publications.map((value, key) => {
                return (
                  <PublicationCardContainer key={key}>
                    <CartePublication
                      id={value.id}
                      title={value.nom}
                      url={value.url}
                      imageUrl={value.imageName}
                      fallbackUrl="mob.jpg"
                    />
                  </PublicationCardContainer>
                );
              })}
            </PublicationGrid>
            <CenterContainer>
            <PageLink to="/ressources">
              <EnSavoirPlus>Voir toutes les publications</EnSavoirPlus>
            </PageLink>
            </CenterContainer>
            </>
          )}

          {projets.length > 0 && (
            <>
            <SecondaryTitle>Les projets récents</SecondaryTitle>
            <ProjetGrid>
              {projets.map((value, key) => {
                return (
                  <ProjetCardContainer key={key}>
                    <CarteProjet
                      id={value.id}
                      title={value.nom}
                      url={value.url}
                      imageUrl="projet.png"
                      fallbackUrl="../default_user.png"
                      etablissements={value.etablissements}
                      enjeux={value.Enjeux_projets}
                    />
                  </ProjetCardContainer>
                );
              })}
            </ProjetGrid>
            <CenterContainer>
            <PageLink to="/projet">
              <EnSavoirPlus>Voir tous les projets</EnSavoirPlus>
            </PageLink>
            </CenterContainer>
            </>
          )}
          
        </main>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>
        
      </div>

      <footer>
        <Footer />
      </footer>

    </div>

  );
}

export default Home;
