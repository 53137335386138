import styled from "styled-components";

export const StageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px ;
  justify-content: center;
  padding-top: 15px;
`;

export const StageCardContainer = styled.div`
  width: 0 0 300px;
  max-width: 500px; /* Limit the card width for smaller screens */
  margin-bottom: 20px;
`;