import styled from "styled-components";
import Colors from "../styles/Colors";

export const PublicationGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px ;
  justify-content: center;
  padding-top: 15px;
`;

export const PublicationCardContainer = styled.div`
  flex: 0 0 300px;
  width: 500px;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  background-color: ${Colors.color2};
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  &:hover {
    background-color: ${Colors.color1};
  }
`;

export const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 990px) {
    margin-bottom: 50px;
  }
`;

export const VideoContainer = styled.div`
  align-items: center;
  width: 100%;
  height: 700px; 
  margin-bottom: 100px;
  @media (max-width: 1700px) {
    height: 600px;
  }
  @media (max-width: 1224px) {
    height: 400px;
  }
  @media (max-width: 950px) {
    height: 300px;
  }
  @media (max-width: 990px) {
    height: 400px;
  }
`;