import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Formik, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";

import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";

import { Label, Input, StyledForm, StyledErrorMessage, StyledSpan,
  StyledLeft, StyledRight, StyledButton, MainStyle } from "../styles/ResetPassword.style";


function ResetPassword() {
    
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [redirectUser, setRedirectUser] = useState(false);
  const token = useParams().token;

  const contactFormSchema = Yup.object().shape({
    password: Yup.string().required("Le mot de passe est requis"),
    confirmPassword: Yup.string()
      .required("Le mot de passe de confirmation est requis")
      .test(
        "passwords-match",
        "Les mots de passe doivent correspondre",
        function (value) {
          return this.parent.password === value;
        }
      ),
  });

  useEffect(() => {
    axios
      .get(`https://back.r3mob.fr/auth/reset-token-verification/${token}`)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
            setRedirectUser(true);
        } else {
          // console.log(response.data);
        }
      });
  }, [token]);

  return (

    <div className="body">

      <div className="main">
        
        <MainStyle>

          <div>

            <Formik
              initialValues={{ nom: "", email: "", message: "" }}
              validationSchema={contactFormSchema}
              onSubmit={(values, { resetForm }) => {
                axios
                  .post("https://back.r3mob.fr/auth/change-password", {
                    password:values.password,
                    token: token,
                  })
                  .then((response) => {
                    if (response.data.error) {
                      alert(response.data.error);
                    } else {
                      alert("Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.");
                      setRedirectUser(true);
                    }
                  });
                resetForm();
              }}
            >

              {({ isSubmitting }) => (
                <StyledForm>

                  <div style={{ marginTop: "30px" }}>

                    <StyledLeft>
                      <Label htmlFor="password">
                        Mot de passe <StyledSpan>*</StyledSpan>
                      </Label>
                    </StyledLeft>

                    <Field
                      type="password"
                      id="password"
                      name="password" // Correspond au nom du champ dans le schéma de validation
                      as={Input}
                      placeholder="password"
                      style={{ width: "100%" }}
                    />

                    <StyledErrorMessage name="password" component="div" />

                  </div>

                  <div style={{ marginTop: "30px" }}>

                    <StyledLeft>
                      <Label htmlFor="confirmPassword">
                        Mot de passe de confirmation <StyledSpan>*</StyledSpan>
                      </Label>
                    </StyledLeft>

                    <Field
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword" // Correspond au nom du champ dans le schéma de validation
                      as={Input}
                      placeholder="Mot de passe de confirmation"
                      style={{ width: "500px" }}
                    />

                    <StyledErrorMessage name="confirmPassword" component="div"/>

                  </div>

                  <StyledRight>
                    <StyledButton type="submit" disabled={isSubmitting}>
                      Envoyer
                    </StyledButton>
                  </StyledRight>

                </StyledForm>
              )}

            </Formik>
          
          </div>
          
          {redirectUser && <Navigate to="/login" />}

        </MainStyle>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default ResetPassword;
