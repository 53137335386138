import Colors from "../styles/Colors";
import styled from "styled-components";

export const Username = styled.a`
  color: var(--color1);
  font-size: medium;
  font-style: italic;
`;

export const ThematiquesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: -5%;
  margin-bottom: 5%;
`;

export const CardImage = styled.img`
  background-position: center;
  width: 100%;
  height: 30%;
  border-radius: 20px 20px 0px 0px;
`;

export const CardContainer = styled.div`
  margin-top: 6%;
  max-width: 300px;
  background-color: ${Colors.color6};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 400px;
  border-radius: 20px;
  animation: ${(props) =>
    props.playAnimation ? "rotate 0.5s ease, zoom 0.5s ease" : "none"};
  @keyframes rotate {
    from {
      transform: rotateY(30deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  &:hover {
    box-shadow: 0 4px 8px rgba(10, 10, 10, 0.5);
  }
`;

export const UsernameContainer = styled.div`
  color: var(--color1);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardContent = styled.div`
  position: relative; /* Add this to create a new stacking context */
  z-index: 1; /* Position the content above the gradient layer */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
`;

export const StageTitle = styled.h2`
  font-size: 1rem;
  color: var(--color1);
  font-weight: bold;
  margin-left: 3%;
  margin-right: 3%;
  line-height: 1.5rem;
  text-align: center;
  justify-content: center;
`;

export const StageInfos = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5%;
  justify-content: space-between;
  margin-right: 5%;
  margin-bottom: 5%;
`;

export const EventLocation = styled.span`
  color: var(--color3);
  /* background-color: rgba(128, 128, 128, 0.7); */
  background-color: ${(props) => (props.isDisponible ? "green" : "red")};
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: bolder;
  font-size: large;
`;