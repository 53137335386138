import styled from 'styled-components';
import Colors from '../styles/Colors';

export const PageButton = styled.button`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 6rem;
  height: 3rem;
  border-radius: 10px;
  border: none;
  background-color: var(--color1);
  color: var(--color3);
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 1075px) {
    font-size: 1rem;
    width: 4rem;
    height: 2rem;
  }

  &:hover {
    background-color: var(--color2);
  }

  &.active {
    background-color: var(--color2);
    color: white;
  }
`;


export const Ellipsis = styled.span`
  color: ${Colors.color2};
`;
