import React, { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { AuthContext } from "../helpers/AuthContext";

import Colors from "../styles/Colors";
import { Label, Input, StyledForm, StyledErrorMessage, StyledSpan,
  MainStyle, TextContainer, LeftContainer, ValidationButton } from "../styles/ChangePassword.style";


function ChangePassword() {

  const { authState } = useContext(AuthContext);

  const contactFormSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Le mot de passe est requis"),
    password: Yup.string().required("Le mot de passe est requis"),
    confirmPassword: Yup.string()
      .required("Le mot de passe de confirmation est requis")
      .test(
        "passwords-match",
        "Les mots de passe doivent correspondre",
        function (value) {
          return this.parent.password === value;
        }
      ),
  });


  return ( 
    authState.status==true ? (

      <div className="body">

        <div className="main">

          <MainStyle>
            
              <Formik
                initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
                validationSchema={contactFormSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  axios
                    .post("https://back.r3mob.fr/auth/change-password-profile", {
                      userId: authState.id,
                      oldPassword: values.oldPassword,
                      password: values.password,
                    })
                    .then((response) => {
                      if (response.data.error) {
                        alert(response.data.error);
                      } else {
                        alert("Votre mot de passe a été changé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.");
                        // Réinitialise le formulaire après une soumission réussie
                        resetForm();
                      }
                      // Définit isSubmitting à false pour indiquer que la soumission est terminée
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.error(error);
                      alert("Une erreur s'est produite lors de la requête.");
                      // Définit isSubmitting à false en cas d'erreur
                      setSubmitting(false);
                    });
                }}
              >

                {({ isSubmitting }) => (

                  <StyledForm>

                    <div style={{ marginTop: "30px" }}>
                      <TextContainer>
                        <Label htmlFor="oldPassword">
                          Ancien Mot de passe <StyledSpan>*</StyledSpan>
                        </Label>
                      </TextContainer>
                      <Field
                        type="password"
                        id="oldPassword"
                        name="oldPassword" 
                        as={Input}
                        placeholder="ancien mot de passe"
                        style={{ width: "100%" }}
                      />
                      <StyledErrorMessage name="oldPassword" component="div" />
                    </div>
                    
                    <div style={{ marginTop: "30px" }}>
                      <TextContainer>
                        <Label htmlFor="password">
                          Nouveau mot de passe <StyledSpan>*</StyledSpan>
                        </Label>
                      </TextContainer>
                      <Field
                        type="password"
                        id="password"
                        name="password" 
                        as={Input}
                        placeholder="nouveau mot de passe"
                        style={{ width: "100%" }}
                      />
                      <StyledErrorMessage name="password" component="div" />
                    </div>

                    <div style={{ marginTop: "30px" }}>
                      <TextContainer>
                        <Label htmlFor="confirmPassword">
                          Mot de passe de confirmation <StyledSpan>*</StyledSpan>
                        </Label>
                      </TextContainer>
                      <Field
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword" 
                        as={Input}
                        placeholder="mot de passe de confirmation"
                        style={{ width: "100%"}}
                      />
                      <StyledErrorMessage name="confirmPassword" component="div" />
                    </div>

                    <LeftContainer>
                      <ValidationButton type="submit" disabled={isSubmitting}>
                        Changer mot de passe
                      </ValidationButton>
                    </LeftContainer>
                    
                    <LeftContainer>
                      <Link to="/profile" style={{color:Colors.color2}}>Retourner sur votre profile</Link>
                    </LeftContainer>

                  </StyledForm>
                )}

              </Formik>
            
        </MainStyle>

        <aside className="mobile">
          {/* <ScrollButton />
          <CarteButton /> */}
        </aside>

      </div>

    </div>
    ):(
      <Navigate to="/login" />
    )
  );
}

export default ChangePassword;
