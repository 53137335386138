import Colors from "../styles/Colors";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form, ErrorMessage } from "formik";

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
  height: 50px;
`;

export const SecondaryTitle = styled.h2`
  color: ${Colors.color2};
  font-size: 2.5rem;
  margin-bottom: 5%;
  align-self: left;
  text-align: center;
`;

export const StyledSpan = styled.span`
  color: red;
  font-weight: bold;
`;
export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  margin-top: 5%;
  padding: 20px;
  overflow: hidden;
`;

export const DescriptionContainer = styled.div`
  background: ${Colors.color6};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  margin-top: 5%;
  border-radius: 100%;
  padding: 5%;
  padding-bottom: 10%;
  overflow: hidden;
`;

export const MailContainer = styled.div`
  background: ${Colors.color6};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  margin-top: 5%;
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  border-top-left-radius: 100% ;
  border-top-right-radius: 100%;
  overflow: hidden;
`;

export const ContactContainer = styled.div`
  margin-bottom: 2.5%;
`;

export const DescriptionReseau = styled.h1`
  color: black;
  font-size: 1.5rem;
  line-height: 1.5;
  /* letter-spacing: 0.1rem; */
  text-align: center;
  font-weight: normal;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 0;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
`;
export const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  gap: 10%;
`;
export const Logos = styled.img`
  width: 350px;
  height: 350px;
  object-fit: contain;
  cursor: pointer;
`;

export const LogoLinkUrl = styled.a`
  text-decoration: none;
  border:0;
  padding:0;
  margin:0;
  background: none;
  color: inherit;
  display: inline-block;
`;

export const LogoLinkPage = styled(Link)`
  text-decoration: none;
  border:0;
  padding:0;
  margin:0;
  background: none;
  color: inherit;
  display: inline-block;
`;

export const TertiaryTitle = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 2.5%;
  text-align: center;
`;

export const Send = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 5%;
`;

export const SendButton = styled.button`
  /* padding: 10px 20px; */
  background: ${Colors.color2};
  color: ${Colors.color3};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
      background: ${Colors.color6};
      color: ${Colors.color2};
    }
`;

export const Contact = styled.p`
  font-size: 1.2rem; 
  text-align: center;
`;

export const VideoContainer = styled.div`
  align-items: center;
  width: 100%;
  height: 700px; 
  /* margin-bottom: 100px; */
  @media (max-width: 1700px) {
    height: 600px;
  }
  @media (max-width: 1224px) {
    height: 400px;
  }
  @media (max-width: 950px) {
    height: 300px;
  }
  @media (max-width: 990px) {
    height: 400px;
  }
`;