import styled from 'styled-components';
import Carte2 from '../assets/TheBrain.png';
import { Link } from "react-router-dom";

export const ButtonContainer = styled.div`
  position: fixed;
  top: 15%;
  left: 25px;
  z-index: 999;
  @media (max-width: 990px) {
    top: 15%;
    right: 0px;
  }
`;

export const CarteBttn = styled(Link)`
  background-color: rgba(232, 67, 30, 0.8);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 8vh;
  width: 6vw;
  background-image: url(${Carte2});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  text-decoration: none; /* Supprime le soulignement des liens */
  position: relative;
  overflow: hidden;

  &:hover::after {
    content: "Afficher la cartographie";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    padding: 0 10px;
    box-sizing: border-box;
    color: white;
    text-align: center;
    word-wrap: break-word;
    max-width: 100%;
  }

  @media (max-width: 990px) {
    height: 9vh;
    width: 6vw;
    &:hover::after {
      content: "Carte";
    }
  }
`;