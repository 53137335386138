import React, { useEffect, useState,useContext } from "react";
import { FaMapMarkerAlt, FaCalendarAlt, FaMoneyBill } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import EventCard from "../components/EventCard";

import "../styles/Commun.css";
import { DetailsContainer, RightSide, LeftSide,
  TitleContainer, EventStatus, EventTitle, EventFieldContainer, 
  DateText, EventIcon, ContactSection, ContactInfo, ContactTitle,
  ContactName, EventImage, EventDescription, EventLink, EventGrid,
  EventCardContainer, SimilarEventsTitle } from "../styles/EventDetails.style";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";

function EventDetails() {

  const { authState } = useContext(AuthContext);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const { eventId } = useParams();
  const [event, setEvent] = useState({});
  const [similarEvents, setSimilarEvents] = useState([]);
  const [image, setImage] = useState(null);

  const customEventURL = `https://back.r3mob.fr/detailsEvent/custom/${eventId}`;
  //console.log("Custom Event URL:", customEventURL);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/detailsEvent/brain/${eventId}`).then((res) => {
      if (res.data !== null) {
        setEvent(res.data);
      } else {
        axios.get(customEventURL).then((res) => {
          if (res.data === null) {
              window.location.replace("/404");
          } else {
              setEvent(res.data);
          }
        });
      }
    });
  }, [eventId]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/similarEvents/${eventId}`).then((res) => {
        setSimilarEvents(res.data.similarEvents);
    });
  }, [eventId]);

  const eventDetails = {
    price: "Gratuitement",
    contact: {
      name: "Cédrik Ferrero",
      address: "cedrik.ferrero@bordeaux-inp.fr",
      poste: "Animateur R3MOB",
    },
    registrationLink: "https://www.bordeaux-inp.fr/fr/formation/2021/PR/ing%C3%A9nieur-enseirb-matmeca",
  };

  const formattedDate = new Date(event.startDateTime).toLocaleDateString(
    "fr-FR",
    {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/event/image/${eventId}`).then((res) => {
      //console.log(res.data);
      if (res.data && res.data !== null) {
        // Vérifiez que res.data n'est pas nul
        setImage(`data:image/png;base64,${res.data}`);
      } else {
        setImage(null);
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  // Remplacer les balises <br/> par des sauts de ligne
  const eventDesc = event.description || "";
  var formattedDescription = eventDesc.replace(/\\t/g, "<pre/>");
  const fallbackImageUrl="../events_imgs/event_default.jpg";
  const backgroundImage = image ? `${image}` : `${fallbackImageUrl}`;

  return (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle href="\agenda" >{"> "} Agenda</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "}{event.nom}</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">

        <main id="EventDetails">

          <TitleContainer>
            <EventStatus> #Événement {event.isPrivate === 't' ? "Privé" : "Public"}</EventStatus>
            <EventTitle>{event.nom}</EventTitle>
          </TitleContainer>

          <DetailsContainer>

            <LeftSide>

              <EventFieldContainer>
                <EventIcon>
                  <FaCalendarAlt />
                </EventIcon>
                <DateText>{formattedDate}</DateText>
              </EventFieldContainer>

              <EventFieldContainer>
                <EventIcon>
                  <FaMapMarkerAlt />
                </EventIcon>
                <div>{event.location}</div>
              </EventFieldContainer>

              {/* <EventFieldContainer>
                <EventIcon>
                  <FaMoneyBill />
                </EventIcon>
                <div>{eventDetails.price}</div>
              </EventFieldContainer>

              {(event.isPrivate === 'f' || authState.status===true) && (
              <EventFieldContainer>
                <EventLink href={eventDetails.registrationLink}>
                  S'inscrire
                </EventLink>
              </EventFieldContainer>
              )} */}

              <ContactSection>
                <ContactTitle>Contact</ContactTitle>
                <ContactName>{eventDetails.contact.name}</ContactName>
                <ContactInfo>{eventDetails.contact.address}</ContactInfo>
                <ContactInfo>{eventDetails.contact.poste}</ContactInfo>
              </ContactSection>

            </LeftSide>

            <RightSide>

              <EventImage src={backgroundImage} alt="Image de l'événement" /> 

            </RightSide>

          
          </DetailsContainer>
          
          {/* {formattedDescription.split("<br/>").map((paragraph, index) => (
            <EventDescription key={index}>
              {paragraph.replaceAll("<pre/>", " ")}
            </EventDescription>
          ))} */}

          <SimilarEventsTitle>Ces événements pourraient vous intéresser</SimilarEventsTitle>

          <EventGrid>
            {similarEvents.map((value, key) => (
              <EventCardContainer key={key}>
                <EventCard
                  id={value.id}
                  date={formatDate(value.startDateTime)}
                  title={value.nom}
                  eventType={value.locationType}
                  location={
                    value.locationType === "Visio"
                      ? "En ligne"
                      : value.location
                  }
                  registrationLink={value.lienInscription}
                  imageUrl = {`../events_imgs/${value.id}.jpg`}
                    fallbackImageUrl = {"../events_imgs/event_default.jpg"}
                />
              </EventCardContainer>
            ))}
          </EventGrid>

        </main>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

  );

};

export default EventDetails;
