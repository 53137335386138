import styled from "styled-components";

// EventDetails.style.js

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: auto; 
  margin-right: auto; 
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  @media (min-width: 990px) {
    align-items: flex-end; /* Aligner les éléments à droite */
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  @media (min-width: 990px) {
    align-items: flex-start; /* Aligner les éléments à gauche */
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto; /* Centrer horizontalement */
  @media (min-width: 990px) {
    align-items: center; /* Reste centré même sur les grands écrans */
    text-align: center; /* Reste centré même sur les grands écrans */
  }
`;


export const EventStatus = styled.h2`
  font-size: 24px;
  color: red;
  margin-left: 10px;
`;

export const EventTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: black;
  margin-top: 3px;
  text-align: center;
`;

export const EventFieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 0;
  gap: 20px;
`;

export const DateText = styled.div`
  font-size: 18px;
  text-transform: capitalize;
`;

export const EventIcon = styled.div`
  width: 24px;
  height: 24px;
  color: red;
  font-size: 24px;
`;

export const ContactSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 0;
  gap: 10px;
`;

export const ContactTitle = styled.h3`
  font-weight: bold;
  color: red;
  font-size: 20px;
`;

export const ContactInfo = styled.div`
  font-style: italic;
  font-size: 16px;
`;

export const ContactName = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

export const EventImage = styled.img`
  max-width: 70%;
  height: auto;
  margin-top: 20px;
  border-radius: 10%;
  align-self: center;
  width: 450px;
`;

export const EventDescription = styled.p`
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  overflow-wrap: break-word;
`;

export const EventLink = styled.a`
  color: var(--color3);
  background-color: var(--color2);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;

  &:hover {
    background-color: var(--color1);
  }
`;

export const EventGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 15px;
  gap: 50px;
`;

export const EventCardContainer = styled.div`
  width: auto;
  margin-bottom: 20px;

  @media (max-width: 990px) {
    width: auto;
  }
`;

export const SimilarEventsTitle = styled.h3`
  margin-top: 40px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: center;
  }
`;