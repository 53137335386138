import styled from "styled-components";

export const EventGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: center;
  padding-top: 15px;
`;

export const EventCardContainer = styled.div`
  position: relative;
  width: auto;
  padding: 20px;
`;
