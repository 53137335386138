import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Field} from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import Colors from "../styles/Colors";
import "../styles/Apropos.css";
import {Label, Input, SecondaryTitle, TertiaryTitle, StyledSpan, 
        InfosContainer, DescriptionReseau, StyledForm, StyledErrorMessage,
        LogosContainer, Logos, LogoLinkUrl, LogoLinkPage, Send, DescriptionContainer,
        SendButton, Contact, ContactContainer, VideoContainer, MailContainer} from "../styles/Apropos.style";

import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";
import { FaAlignCenter } from "react-icons/fa";
/* import ListeActeur from "../components/ListeActeur";
import BordeauxMap from "../components/BordeauxMap"; */

function Apropos() {

  const [infos, setInfos] = useState([]);
  const [videos , setVideos] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/infos/apropos`).then((res) => {
      setInfos(res.data);
      console.log("infos: ",res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/videos`).then((res) => {
      setVideos(res.data);
    });
  }, [videos]);

  useEffect(() => {
    const handleScroll = () => {
      const whoAreWeElement = document.getElementById("who-are-we");
      const contactSection = document.getElementById("contact-section");
      const whoAreWePosition = whoAreWeElement.getBoundingClientRect().top;
      const contactPosition = contactSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (whoAreWePosition < windowHeight * 0.75) {
        setIsVisible(true);
      }
      if (contactPosition < windowHeight * 0.75) {
        setContactVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const contactFormSchema = Yup.object().shape({
    nom: Yup.string().required("Le nom est requis"),
    email: Yup.string()
      .email("L'adresse email n'est pas valide")
      .required("L'adresse email est requise"),
    sujet: Yup.string().required("Le sujet est requis"),
    message: Yup.string().required("Le message est requis"),
  });

  return (
    <div className="body">

      <header>
        <HeaderContent>
          <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
          <HeaderLinkStyle>{"> "}À propos du réseau</HeaderLinkStyle>
        </HeaderContent>
      </header>

      <div className="main">

        <main>
          
          <LogosContainer>
            <LogoLinkPage to="/">
              <Logos src="logo.png" alt="Logo" className="logo" />
            </LogoLinkPage>
            <LogoLinkUrl href="https://www.nouvelle-aquitaine.fr/" target="_blank" rel="noopener noreferrer">
              <Logos src="Logo_Nouvelle-Aquitaine.png" alt="Nouvelle aquitaine" className="na" />
            </LogoLinkUrl>
          </LogosContainer>
          
          <DescriptionContainer>
            <SecondaryTitle id="who-are-we" className={isVisible ? "slideIn" : "hidden"}>Qui sommes-nous ? </SecondaryTitle>
            <DescriptionReseau className={isVisible ? "slideIn" : "hidden"}>{infos.descriptionReseau}</DescriptionReseau>
          </DescriptionContainer>
          
          {/* <InfosContainer>
            <SecondaryTitle>R3MOB en quelques chiffres ... </SecondaryTitle>
            <ListeActeur />
            <BordeauxMap />
          </InfosContainer> */}

          { videos.length > 0 ? (
            <InfosContainer>
              <VideoContainer>
                <iframe width="1850" height="799" src={videos[videos.length-1].url} title={videos[videos.length-1].title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                
              </VideoContainer>
            </InfosContainer>
          ) : (
            <p></p>
          )}
          </main>
        

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>  
          <MailContainer id="contact-section" className={contactVisible ? "slideUp" : "hidden"}>

            <SecondaryTitle>Nous Contacter </SecondaryTitle>
            
            <TertiaryTitle>Pour plus d'information, vous pouvez contacter :</TertiaryTitle>
            <ContactContainer>
            <Contact>
              Mohamed Mosbah - Pilote : <a style={{color: Colors.color2}} href="Mailto:mohamed.mosbah@u-bordeaux.fr">mohamed.mosbah@u-bordeaux.fr</a>
            </Contact>
            <Contact>
              Yacine GHAMRI-Doudane - Copilote : <a style={{color: Colors.color2}} href="Mailto:yacine.ghamri@univ-lr.fr">yacine.ghamri@univ-lr.fr</a>
            </Contact>
            <Contact>
              Cedric Ferrero - Coordinateur : <a style={{color: Colors.color2}} href="Mailto:cedrik.ferrero@bordeaux-inp.fr">cedrik.ferrero@bordeaux-inp.fr</a>
            </Contact>
            </ContactContainer>

            <TertiaryTitle>Sinon, vous pouvez nous écrire : </TertiaryTitle>

            <Formik
              initialValues={{ nom: "", email: "", message: "",sujet:"" }}
              validationSchema={contactFormSchema}
              onSubmit={(values, { resetForm }) => {
                axios.post("https://back.r3mob.fr/sendMail/contact", {
                  nom: values.nom.trim(),
                  email: values.email.trim(),
                  sujet: values.sujet.trim(),
                  message: values.message.trim(),
                }).then((response) => {
                  if (response.data.error) {
                    alert(response.data.error);
                  } else {
                    alert("Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.");
                  }
                });
                resetForm(); // Réinitialise le formulaire après la soumission
              }}
            >

            {({ isSubmitting }) => (
              <StyledForm>

                <div style={{ marginTop: '2.5%' }}>
                  <Label htmlFor="nom">Nom <StyledSpan>*</StyledSpan></Label>
                  <Field
                    type="text"
                    id="nom"
                    name="nom"
                    as={Input}
                    placeholder="Votre nom"
                    style={{ width: '100%' }}
                  />
                  <StyledErrorMessage name="nom" component="div" />
                </div>
          
                <div style={{ marginTop: '2.5%' }}>
                  <Label htmlFor="email">Adresse Email <StyledSpan>*</StyledSpan></Label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    as={Input}
                    placeholder="Votre adresse email"
                    style={{ width: '100%' }}
                  />
                  <StyledErrorMessage name="email" component="div" />
                </div>
          
                <div style={{ marginTop: '2.5%' }}>
                  <Label htmlFor="sujet">Sujet <StyledSpan>*</StyledSpan></Label>
                  <Field
                    type="text"
                    id="sujet"
                    name="sujet"
                    as={Input}
                    placeholder="Le sujet de votre message"
                    style={{ width: '100%' }}
                  />
                  <StyledErrorMessage name="sujet" component="div" />
                </div>
          
                <div style={{ marginTop: '2.5%' }}>
                  <Label htmlFor="message">Message <StyledSpan>*</StyledSpan></Label>
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    rows="10"
                    placeholder="Votre message"
                    style={{ width: '100%', fontSize: '1.2rem',maxWidth: '100%' }}
                  />
                  <StyledErrorMessage name="message" component="div" />
                </div>
          
                <Send>
                  <SendButton type="submit" disabled={isSubmitting}>
                    Envoyer
                  </SendButton>
                </Send>

          </StyledForm>
          )}

        </Formik>
            
        </MailContainer>

        

      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Apropos;
