import React, { useState, useEffect } from "react";
import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PopupContainer, Title, CloseButton, PopupContent,
  ResetButton, ButtonContainer, Input, Label, InputSection,
  Ul, Li } from "../styles/CreatePubPopup.style";


const FindMailPopup = ({ onClose }) => {

  const [nom, setNom] = useState("");
  const [emailsPerso, setEmailsPerso] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/perso/emails`).then((res) => {
      setEmailsPerso(res.data);
    });
  }, []);

  // Fonction de mise à jour des suggestions
  useEffect(() => {
    if (nom.trim() === "") {
      setSuggestions([]); // Aucune suggestion si le champ est vide
    } else {
      const filteredSuggestions = emailsPerso.filter((email) =>
        email.username.trim().toLowerCase().includes(nom.trim().toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  }, [nom, emailsPerso]);

  return (

    <PopupContainer>
      <PopupContent>

        <Title>Trouver votre email</Title>

        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          <Label>Nom:</Label>
          <Input
            onChange={(e) => {
              setNom(e.target.value);
            }}
            type="text"
            placeholder="Insérer votre nom"
            value={nom}
          />
        </InputSection>

        <Ul>
          {suggestions.map((suggestion) => (
            <Li key={suggestion.username}>{suggestion.email}</Li>
          ))}
        </Ul>

      </PopupContent>
    </PopupContainer>

  );

};

export default FindMailPopup;
