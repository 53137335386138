import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

import {
  PopupContainer, Title, CloseButton, PopupContent, ConfirmButton,
  ResetButton, ButtonContainer, Input, Textarea, Label,
  InputSection } from "../styles/EditEventPopup.style";


const AddVideoPopup = ({ onSave, onClose }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const handleResetChanges = () => {
    // Reset all the fields here
    setTitle("");
    setUrl("");
  };

  const handleSaveChanges = () => {
    const createdVideo = {
      title: title,
      url:url,
    };
    onSave(createdVideo);
  };

  return (

    <PopupContainer>

      <PopupContent>

        <Title>Ajouter une vidéo</Title>

        <CloseButton onClick={onClose} data-testid="close-button">
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <InputSection>
          <Label>Titre:</Label>
          <Input
            type="text"
            placeholder="Titre de la vidéo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </InputSection>

        <InputSection>
          <Label>Url de la vidéo:</Label>
          <Textarea
            rows="5"
            placeholder="Lien url vers la vidéo"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </InputSection>

        <ButtonContainer>
          <ResetButton onClick={handleResetChanges} data-testid="reset-button">Réinitialiser</ResetButton>
          <ConfirmButton onClick={handleSaveChanges} data-testid="confirm-button">Confirmer</ConfirmButton>
        </ButtonContainer>

      </PopupContent>
      
    </PopupContainer>
  );
}

export default AddVideoPopup;
