import React, { useEffect, useState } from "react";
import axios from "axios";

import { Table, Th, Td, TableContainer, Input,
  StyledTd, ConfirmButton, ResetButton, ButtonContainer,
  Textarea } from "../styles/UpdateEvent.style";


function UpdatePublicInfos() {

  const [originalJsonData, setOriginalJsonData] = useState({});
  const [jsonData, setJsonData] = useState({
    lienCartographie: "",
    Apropos: {
      descriptionReseau: "",
      enjeuxReseaux: "",
    },
    reseauxSociaux: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
      LinkedIn: "",
    },
  });

  useEffect(() => {
    axios
      .get("https://back.r3mob.fr/infos/all")
      .then((response) => {
        setJsonData(response.data);
        setOriginalJsonData(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors du chargement du fichier JSON : ", error);
      });
  }, []);

  const handleInputChange = (event, field, subField = "") => {
    const newValue = event.target.value;
    setJsonData((prevData) => {
      if (subField) {
        return {
          ...prevData,
          [field]: {
            ...prevData[field],
            [subField]: newValue,
          },
        };
      } else {
        return {
          ...prevData,
          [field]: newValue,
        };
      }
    });
  };

  const updateJsonFile = () => {
    console.log("Mise à jour du fichier JSON...");
    axios
      .put("https://back.r3mob.fr/infos", jsonData) 
      .then((response) => {
        console.log("Fichier JSON mis à jour avec succès.");
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la mise à jour du fichier JSON : ",
          error
        );
      });
  };

  const resetJsonData = () => {
    // Réinitialisez les données JSON à partir de la copie d'origine
    setJsonData(originalJsonData);
  };


  return (

    <TableContainer>

      <Table>

        <thead>
          <tr>
            <Th>Nom du champ</Th>
            <Th>Valeur Actuelle</Th>
            <Th>Actions</Th>
          </tr>
        </thead>

        <tbody>

          <tr>
            <Td style={{width: "10%"}}>
              Lien Cartographie
            </Td>
            <Td style={{width: "70%"}}>
              {jsonData.lienCartographie}
            </Td>
            <StyledTd>
              <Input
                type="text"
                placeholder="Nouvelle valeur lien cartographie"
                value={jsonData.lienCartographie} // Lier la valeur du champ d'entrée à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "lienCartographie")
                } // Appeler la fonction de gestion de la saisie lorsqu'une modification est apportée
              />
            </StyledTd>
          </tr>

          <tr>
            <Td style={{width: "30%"}}>
              Description à propos
            </Td>
            <Td>{jsonData.Apropos.descriptionReseau}</Td>
            <StyledTd>
              <Textarea
                rows="4"
                cols="50"
                placeholder="Nouvelle description à propos"
                value={jsonData.Apropos.descriptionReseau} // Lier la valeur du champ de texte à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "Apropos", "descriptionReseau")
                } // Appeler la fonction de gestion de la saisie pour cette sous-clé
              />
            </StyledTd>
          </tr>

          <tr>
            <Td style={{width: "30%"}}>
              Facebook
            </Td>
            <Td>{jsonData.reseauxSociaux.Facebook}</Td>
            <StyledTd>
              <Input
                type="text"
                placeholder="Nouveau lien Facebook"
                value={jsonData.reseauxSociaux.Facebook} // Lier la valeur du champ de texte à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "reseauxSociaux", "Facebook")
                }
              />
            </StyledTd>
          </tr>

          <tr>
            <Td style={{width: "30%"}}>
              Linkedin
            </Td>
            <Td>{jsonData.reseauxSociaux.LinkedIn}</Td>
            <StyledTd>
              <Input
                type="text"
                placeholder="Nouveau lien Linkedin"
                value={jsonData.reseauxSociaux.LinkedIn} // Lier la valeur du champ de texte à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "reseauxSociaux", "LinkedIn")
                }
              />
            </StyledTd>
          </tr>

          <tr>
            <Td style={{width: "30%"}}>
              Twitter
            </Td>
            <Td>{jsonData.reseauxSociaux.Twitter}</Td>
            <StyledTd>
              <Input
                type="text"
                placeholder="Nouveau lien Twitter"
                value={jsonData.reseauxSociaux.Twitter} // Lier la valeur du champ de texte à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "reseauxSociaux", "Twitter")
                }
              />
            </StyledTd>
          </tr>

          <tr>
            <Td style={{width: "30%"}}>
              Instagram
            </Td>
            <Td>{jsonData.reseauxSociaux.Instagram}</Td>
            <StyledTd>
              <Input
                type="text"
                placeholder="Nouveau lien Instagram"
                value={jsonData.reseauxSociaux.Instagram} // Lier la valeur du champ de texte à la valeur JSON actuelle
                onChange={(event) =>
                  handleInputChange(event, "reseauxSociaux", "Instagram")
                }
              />
            </StyledTd>
          </tr>

        </tbody>

      </Table>

      <ButtonContainer>

        <ConfirmButton onClick={updateJsonFile}>
          Valider toutes les modifications
        </ConfirmButton>

        <ResetButton onClick={resetJsonData}>
          Réinitialiser toutes les valeurs
        </ResetButton>

      </ButtonContainer>

    </TableContainer>

  );

}


export default UpdatePublicInfos;
