import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import CartePerso from "../components/CartePerso";
import Thematiques from "../components/Thematiques";
import Footer from "../components/Footer";
import Defiler from "../components/Defiler";

import "../styles/Commun.css";
import { HorizontalLine, ReinitialiserButton, InputSection, Input,  
  FilterTitle, StyledSearchIcon, StyledSelect, StyledFilterSearch, FilterStyle,
  CheckboxContainer, CheckboxLabel, CheckboxInput, SousThematiqueGroup,
  Label, Horizontal } from "../styles/Filter.style";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { EventGrid, EventCardContainer } from "../styles/Chercheur.style";


function Chercheur() {
  
  const { authState } = useContext(AuthContext);
  const [persos, setPersos] = useState([]);
  const [etablissements, setEtablissements] = useState([]);
  const [allSousThematiques, setAllSousThematiques] = useState([]);
  const [allThematiques, setAllThematiques] = useState([]);
  
  const [chercheurNameFilter, setchercheurNameFilter] = useState("");
  const [etablissementFilter, setEtablissementFilter] = useState("");
  const [personnelTypeFilter, setPersonnelTypeFilter] = useState("Tous");
  const [comiteTypeFilter, setComiteTypeFilter] = useState("Tous");
  const [thematiqueFilter, setThematiqueFilter] = useState("");
  const [sousThematiqueFilter, setSousThematiqueFilter] = useState([]);
  const [sousThematiquesSelectFilter, setSousThematiquesSelectFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSortType] = useState("ascending");
  var [loading, setLoading] = useState(true);


  useEffect(() => {
    axios.get(`https://back.r3mob.fr/etablissement`).then((res) => {
      setEtablissements(res.data);
      //console.log(res.data);
    });
    axios.get(`https://back.r3mob.fr/sousThematique`).then((res) => {
      setAllSousThematiques(res.data);
      //console.log(res.data);
    });
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      setAllThematiques(res.data);
      //console.log(res.data);
    });
    axios.get(`https://back.r3mob.fr/perso`).then((res) => {
      setPersos(res.data);
      //console.log(res.data);
    });
  }, []);


  const filteredPersos = persos
    .filter((perso) => {
      const etablissementNames = perso.Chercheur_etabs.map((chercheurEtab) => {
        const etablissement = etablissements.find(
          (etab) => etab.id === chercheurEtab.EtablissementId
        );
        return etablissement ? etablissement.nom.toLowerCase() : "";
      });
      const hasNoEtablissement = etablissementNames.length === 0;
      return (
        perso.username.toLowerCase()
          .includes(chercheurNameFilter.trim().toLowerCase()) &&
        (hasNoEtablissement || etablissementNames.some((name) =>
          name.trim().includes(etablissementFilter.trim().toLowerCase())
        )) && (personnelTypeFilter === "Tous" ||
          perso.Type_personnels.some((type) => type.type === personnelTypeFilter)) &&
          (comiteTypeFilter === "Tous" ||
          (comiteTypeFilter === "CE" && perso.isComiteExecutif) ||
          (comiteTypeFilter === "CP" && perso.isComitePilotage))
      );
    })
    .filter((chercheur) => {
      if (thematiqueFilter === "") {
        return true; 
      }
      if (chercheur.Thematique_chercheurs) {
        return chercheur.Thematique_chercheurs.some(
          (thematiqueChercheur) => {
            const thematiqueNom = thematiqueChercheur.SousThematique?.Thematique?.nom || "";
            return thematiqueNom.toLowerCase() === thematiqueFilter.toLowerCase() || thematiqueChercheur.Thematique?.nom.toLowerCase() === thematiqueFilter.toLowerCase();
          }
        );
      }
      return false;
    })
    .filter((chercheur) => {
      if (sousThematiqueFilter.length === 0) {
        return true; 
      }
      return chercheur.Thematique_chercheurs.some((thematiqueChercheur) => {
        const sousThematiqueNom = thematiqueChercheur.SousThematique?.nom || "";
        return sousThematiqueFilter.includes(sousThematiqueNom.toLowerCase());
      });
    });

  useEffect(() => {
    setLoading(false);
  }, [filteredPersos]);

  const sortedPersos = filteredPersos.sort((a, b) => {
    if (sortType === "ascending") {
      return a.username.localeCompare(b.username);
    } else {
      return b.username.localeCompare(a.username);
    }
  });

  const eventsPerPage = 9;
  const totalPages = Math.ceil(sortedPersos.length / eventsPerPage);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentPageContent = sortedPersos.slice(indexOfFirstEvent, indexOfLastEvent);

  // Fonction pour aller à la page choisie
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /* Fonctions pour gérer les changements de filtres */

  const handleSearchInputChange = (event) => {
    setchercheurNameFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (event) => {
    setSortType(event.target.value);
    setCurrentPage(1);
  };

  const handleEtablissementFilterChange = (event) => {
    setEtablissementFilter(event.target.value);
    setCurrentPage(1);
  };
  const handlePersonnelTypeFilterChange = (event) => {
    setPersonnelTypeFilter(event.target.value);
    setCurrentPage(1);
  };
  const handleComiteTypeFilterChange = (event) => {
    setComiteTypeFilter(event.target.value);
    setCurrentPage(1);
  };
  const handleThematiqueFilterChange = (selectedThematique) => {
    setThematiqueFilter(selectedThematique);
    const sousThematiquesFiltrees = allSousThematiques.filter(
      (sousThematique) => {
        return sousThematique.Thematique.nom === selectedThematique;
      }
    );

    setSousThematiquesSelectFilter(sousThematiquesFiltrees);
    setCurrentPage(1);
  };

  const handleSousThematiqueFilterChange = (selectedSousThematique) => {
    if (sousThematiqueFilter.includes(selectedSousThematique)) {
      setSousThematiqueFilter(
        sousThematiqueFilter.filter(
          (sousThematique) => sousThematique !== selectedSousThematique
        )
      );
    } else {
      setSousThematiqueFilter([...sousThematiqueFilter, selectedSousThematique]);
    }
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setchercheurNameFilter("");
    setEtablissementFilter("");
    setPersonnelTypeFilter("Tous");
    setComiteTypeFilter("Tous");
    setThematiqueFilter("");
    setSousThematiquesSelectFilter([]);
    setSousThematiqueFilter([]);
    setCurrentPage(1);
  };

  return (

    authState.status==true ? (
    
    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle href="\annuaires" >{"> "} Annuaire</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "} Acteurs R3MOB</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">

        <div className="chercheurs section">
        
          <main>

            <h1 className="mainTitle">Acteurs R3MOB</h1>
            {loading === true ? (
              <PropagateLoader cssOverride={{
                "display": "flex",
                "justifyContent": "center", "alignItems": "center", "height": "100vh"
              }}
              color="#36d7b7" />
            ) : (
            currentPageContent.length != 0 ? (
            <EventGrid>
            
              {currentPageContent.map((value, key) => {
                const etablissementNames = value.Chercheur_etabs.map(
                  (chercheurEtab) => {
                    const etablissement = etablissements.find(
                      (etab) => etab.id === chercheurEtab.EtablissementId
                    );
                    return etablissement ? etablissement.nom : "Pas indiqué";
                  }
                );

                return (
                  <EventCardContainer key={key}>
                    <CartePerso
                      id={value.id}
                      name={value.username}
                      email={value.email ? value.email : "Pas indiqué"}
                      // phone={value.phone ? value.phone : "Pas indiqué"}
                      address={etablissementNames.join(", ")} // Join establishment names with commas
                      imageData={value.imageData}
                    />
                  </EventCardContainer>
                );
              })}
            </EventGrid>
            ) : (
              <p>Aucun acteur R3MOB ne correspond aux filtres sélectionnés.</p>
            )
            )}

          <Defiler
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageClick={handlePageClick}
          />

          </main>

          <aside className="filter">
            
            <FilterStyle>
              <FilterTitle>
                Filtrer par <HorizontalLine />
              </FilterTitle>

              <InputSection>
                <div style={{ position: "relative" }}>
                  <StyledSearchIcon />
                  <Input
                    type="text"
                    value={chercheurNameFilter}
                    onChange={handleSearchInputChange}
                    placeholder="Rechercher par nom"
                  />
                </div>
              </InputSection>

              <InputSection>
                <div style={{ position: "relative" }}>
                  <StyledFilterSearch />
                  <Input
                    type="text"
                    value={etablissementFilter}
                    onChange={handleEtablissementFilterChange}
                    placeholder="Filtrer par établissement"
                  />
                </div>
              </InputSection>

              <StyledSelect
                value={personnelTypeFilter}
                onChange={handlePersonnelTypeFilterChange}
              >
                <option value="Tous">Tous</option>
                <option value="C">Enseignant Chercheur</option>
                <option value="D">Directions</option>
                <option value="A">Autres Personnels</option>
              </StyledSelect>

              <StyledSelect
                value={comiteTypeFilter}
                onChange={handleComiteTypeFilterChange}
              >
                <option value="Tous">Tous</option>
                <option value="CE">Comité Exécutif</option>
                <option value="CP">Comité pilotage</option>
              </StyledSelect>

              <StyledSelect
                value={thematiqueFilter}
                onChange={(e) => handleThematiqueFilterChange(e.target.value)}
              >
                <option value="">Toutes les thematiques</option>
                {Thematiques.map((thematique, index) => (
                  <option key={index} value={thematique.nom}>
                    {thematique.nom}
                  </option>
                ))}
              </StyledSelect>

              {sousThematiquesSelectFilter.length > 0 && (
                <><Label><Horizontal />Sous-Thématiques</Label>
                <SousThematiqueGroup>
                <CheckboxContainer>
                  {sousThematiquesSelectFilter.map((sousThematique) => (
                    <CheckboxLabel key={sousThematique.id}>
                      <CheckboxInput
                        type="checkbox"
                        checked={sousThematiqueFilter.includes(sousThematique.nom.toLowerCase())}
                        onChange={() => handleSousThematiqueFilterChange(sousThematique.nom.toLowerCase())}
                      />
                      {sousThematique.nom}
                    </CheckboxLabel>
                  ))}
                </CheckboxContainer>
                </SousThematiqueGroup></>
              )}

              <ReinitialiserButton onClick={handleResetFilters}>Réinitialiser les filtres</ReinitialiserButton>

              <FilterTitle>
                Trier par <HorizontalLine />
              </FilterTitle>

              <StyledSelect value={sortType} onChange={handleSortChange}>
                <option value="ascending">Nom croissant</option>
                <option value="descending">Nom décroissant</option>
              </StyledSelect>
            </FilterStyle>
          </aside>
        
        </div>
        
        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>
    
    </div>

    ):(
      <Navigate to="/login" />
    )

  );
}

export default Chercheur;
