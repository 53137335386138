import React, { useState, useEffect } from "react";
import axios from "axios";

import Footer from "../components/Footer";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import CarteStage from "../components/CarteStage";
import Defiler from "../components/Defiler";

import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { InputSection, Input, StyledSearchIcon, HorizontalLine,
   ReinitialiserButton, FilterTitle,StyledSelect, FilterStyle } from "../styles/Filter.style";
import { StageGrid, StageCardContainer  } from "../styles/StageEtEmploi.style";
import "../styles/Commun.css";

function StageEtEmploi() {

  const [stages, setStages] = useState([]);
  const [emplois, setEmplois] = useState([]);
  const [currentPageStage, setCurrentPageStage] = useState(1);
  const [currentPageEmploi, setCurrentPageEmploi] = useState(1);
  const [stageFilter, setStageFilter] = useState("");
  const [emploiFilter, setEmploiFilter] = useState("");
  const [etatStageFilter, setEtatStageFilter] = useState("D");
  const [etatEmploiFilter, setEtatEmploiFilter] = useState("D");


  useEffect(() => {
    axios.get(`https://back.r3mob.fr/stages`).then((res) => {
      setStages(res.data);
    });
    axios.get(`https://back.r3mob.fr/emplois`).then((res) => {
      setEmplois(res.data);
    });
  }, []);

  const filteredStages = stages
    .filter((stage) =>
      stage.description.toLowerCase().includes(stageFilter.trim().toLowerCase())
    )
    .filter((stage) => {
      if (etatStageFilter === "") return true;
      if (etatStageFilter === "D") return stage.disponible === true;
      if (etatStageFilter === "ND") return stage.disponible === false;
    });

  const filteredEmplois = emplois
    .filter((emploi) =>
      emploi.description.toLowerCase().includes(emploiFilter.trim().toLowerCase())
    )
    .filter((emploi) => {
      if (etatEmploiFilter === "") return true;
      if (etatEmploiFilter === "D") return emploi.disponible === true;
      if (etatEmploiFilter === "ND") return emploi.disponible === false;
    });

  const cardPerPage = 6;

  /*Pages pour les stages*/
  const totalPages = Math.ceil(stages.length / cardPerPage);
  
  const indexOfLastStage = currentPageStage * cardPerPage;
  const indexOfFirstStage = indexOfLastStage - cardPerPage;
  const currentStages = filteredStages.slice(indexOfFirstStage, indexOfLastStage);

  /*Pages pour les emplois*/
  const totalPagesEmploi = Math.ceil(emplois.length / cardPerPage);

  const indexOfLastEmploi = currentPageEmploi * cardPerPage;
  const indexOfFirstEmploi = indexOfLastEmploi - cardPerPage;
  const currentEmploi = filteredEmplois.slice(indexOfFirstEmploi, indexOfLastEmploi);

  // Fonction pour aller à la page choisie des Stages
  const handlePageClick = (pageNumber) => {
    setCurrentPageStage(pageNumber);
  };

  // Fonction pour aller à la page choisie des Emplois
  const handlePageClickEmploi = (pageNumber) => {
    setCurrentPageEmploi(pageNumber);
  };

  /* Fonctions pour gérer les changements de filtres */

  const handleStageFilterChange = (event) => {
    setStageFilter(event.target.value);
  };

  const handleEmploiFilterChange = (event) => {
    setEmploiFilter(event.target.value);
  };

  return (

    <div className="body">
      
      <header>
        <HeaderContent>
          <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
          <HeaderLinkStyle >{"> "} Stage & emploi</HeaderLinkStyle>
        </HeaderContent>
      </header>
      
      <div className="main">
      
      <div className="stages section">
      
        <main>

          <h1 className="mainTitle">Stages</h1>

          {currentStages.length != 0 ? (

            <StageGrid>
              {currentStages.map((value, key) => {
                return (
                  <StageCardContainer key={key}>
                    <CarteStage
                      id={value.id}
                      title={value.description}
                      disponible={value.disponible}
                      url = {value.url}
                      imageUrl="stages.png"
                      fallbackUrl="../default_user.png"
                    />
                  </StageCardContainer>
                );
              })}
            </StageGrid>

          ) : (
            <p>Aucun stage trouvé</p>
          )}
          
          <Defiler
              currentPage={currentPageStage}
              totalPages={totalPages}
              handlePageClick={handlePageClick}
          />
        
        </main>

        <aside className="filter">
          <FilterStyle>

          <FilterTitle>
            Filtrer par <HorizontalLine />
          </FilterTitle>

          <InputSection>
            <div style={{ position: "relative" }}>
              <StyledSearchIcon />
              <Input
                type="text"
                placeholder="Recherche par description"
                value={stageFilter}
                onChange={handleStageFilterChange} // Gestionnaire de changement pour les stages
              />
            </div>
          </InputSection>

          <StyledSelect
            value={etatStageFilter}
            onChange={(e) => setEtatStageFilter(e.target.value)}
          >
            <option value="">Tous les stages</option>
            <option value="D">Disponible </option>
            <option value="ND">Non disponible </option>
          </StyledSelect>

          <ReinitialiserButton onClick={() => {
            setStageFilter("");
            setEtatStageFilter("");
          }}>
            Réinitialiser les filtres
          </ReinitialiserButton>

          </FilterStyle>
        </aside>

      </div>

      <div className="emplois section">
        
        <main>

          <h1 className="mainTitle">Emplois</h1>
          {currentEmploi.length != 0 ? (
            <StageGrid>
              {currentEmploi.map((value, key) => {
                return (
                  <StageCardContainer key={key}>
                    <CarteStage
                      id={value.id}
                      title={value.description}
                      imageUrl="emploi.jpg"
                      fallbackUrl="../default_user.png"
                    />
                  </StageCardContainer>
                );
              })}
            </StageGrid>
          ) : (
            <p>Aucun emploi trouvé</p>
          )}

          <Defiler
              currentPage={currentPageEmploi}
              totalPages={totalPagesEmploi}
              handlePageClick={handlePageClickEmploi}
          />
          
        </main>

        <aside className="filter">

          <FilterStyle>

          <FilterTitle>
            Filtrer par <HorizontalLine />
          </FilterTitle>

          <InputSection>
            <div style={{ position: "relative" }}>
              <StyledSearchIcon />
              <Input
                type="text"
                placeholder="Recherche par description"
                value={emploiFilter}
                onChange={handleEmploiFilterChange} // Gestionnaire de changement pour les emplois
              />
            </div>
          </InputSection>

          <StyledSelect
            value={etatEmploiFilter}
            onChange={(e) => setEtatEmploiFilter(e.target.value)}
          >
            <option value="">Tous les emplois</option>
            <option value="D">Disponible </option>
            <option value="ND">Non disponible </option>
          </StyledSelect>

          <ReinitialiserButton onClick={() => {
            setEmploiFilter("");
            setEtatEmploiFilter("");
          }}>
            Réinitialiser les filtres
          </ReinitialiserButton>
          
          </FilterStyle>
          
        </aside>

      </div>
      
      <aside className="mobile">
        <ScrollButton />
        <CarteButton />
      </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

  );
}

export default StageEtEmploi;
