import styled from "styled-components";
import Colors from "../styles/Colors";

export const Loader = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #666;
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 5px;
  margin-top: 50px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
`;

export const ConfirmButton = styled.button`
  width: 400px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: ${Colors.color2};
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    color: ${Colors.color2};
    background-color: ${Colors.color6};
  }
`;