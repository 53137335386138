import React from "react";

import { ScrollButton, Icon, TooltipContainer, TooltipContent } from "../styles/ThematiqueIcon.style";


function ThematiqueIcon({ icon, backgroundColor, subThematiques }) {

  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (

    <TooltipContainer
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >

      <ScrollButton backgroundColor={backgroundColor}>
          <Icon src={icon} alt="Icon" />
      </ScrollButton>

      <TooltipContent visible={showTooltip}>
      {subThematiques.map((subThematique, index) => (
          <div key={index}>{subThematique}</div>
        ))}
      </TooltipContent>

    </TooltipContainer>

  );

}

export default ThematiqueIcon;
