import Colors from "../styles/Colors";
import styled from "styled-components";
import { Form, ErrorMessage } from "formik";

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
  height: 50px;
`;
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
  width: 500px;
  @media (max-width: 1075px) {
    width: 100%;
  }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
`;
export const StyledSpan = styled.span`
  color: red;
  font-weight: bold;
`;

export const MainStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  gap: 20px;
  space-between: 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
`;

export const ValidationButton = styled.button`
  padding: 10px 20px;
  background: ${Colors.color2};
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  width: fit-content;

`;