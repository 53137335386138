import axios from "axios";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../helpers/AuthContext";
import ScrollButton from "../components/ScrollButton";
import CarteButton from "../components/CarteButton";
import Footer from "../components/Footer";

import "../styles/Commun.css";
import { HeaderContent, HeaderLinkStyle } from "../styles/Header.style";
import { HorizontalLine, ReinitialiserButton, InputSection, 
         Input, FilterTitle, StyledSearchIcon, FilterStyle } from "../styles/Filter.style";
import { TooltipContent, mainStyles, ActeurImage } from "../styles/Acteurs.style";
import { EventGrid, EventCardContainer } from "../styles/Chercheur.style";

function AutreEtab() {

  const [autreEtab, setAutreEtab] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { authState } = useContext(AuthContext);


  useEffect(() => {
    axios.get("https://back.r3mob.fr/etablissement/autre").then((response) => {
      setAutreEtab(response.data);
    });
  }, []);

  const filteredAutreEtab = autreEtab.filter((item) =>
    item.nom.toLowerCase().includes(searchValue.trim().toLowerCase())
  );

  const defaultImage = 'Université.png';

  return (
    authState.status==true ? (

    <div className="body">

      <header>
      <HeaderContent>
        <HeaderLinkStyle href="\">{"> "}Accueil</HeaderLinkStyle>
        <HeaderLinkStyle href="\annuaires" >{"> "} Annuaire</HeaderLinkStyle>
        <HeaderLinkStyle >{"> "} Autre Etablissement</HeaderLinkStyle>
      </HeaderContent>
      </header>

      <div className="main">

        <div className="etablissements section">
          
          <main style={mainStyles}>

            <h1 className="mainTitle">Autres Établissements</h1>
            
            <EventGrid>
              {filteredAutreEtab.map((item, index) => (
                <EventCardContainer
                  key={item.id}
                  onMouseEnter={() => setTooltipIndex(index)}
                  onMouseLeave={() => setTooltipIndex(null)}
                >
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <ActeurImage backgroundImage={item.imageData
                      ? `url(data:image/png;base64,${item.imageData})`
                      : `url(${defaultImage})`} 
                    />
                  </a>
                  
                  <TooltipContent visible={tooltipIndex === index}>
                    <p className="nom-text">{item.nom}</p>{item.label && (<span className="label-text">{item.label}</span>)}
                  </TooltipContent>
                  
                </EventCardContainer>
              ))}
            </EventGrid>

          </main>
          
          <aside className="filter">
            <FilterStyle>

            <FilterTitle>
              Filtrer par <HorizontalLine />
            </FilterTitle>

            <InputSection>
              <div style={{ position: "relative" }}>
                <StyledSearchIcon />
                <Input
                  type="text"
                  placeholder="Rechercher par nom"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </InputSection>

            <ReinitialiserButton>Réinitialiser les filtres</ReinitialiserButton>
            
            </FilterStyle>
          </aside>

        </div>

        <aside className="mobile">
          <ScrollButton />
          <CarteButton />
        </aside>

      </div>

      <footer>
        <Footer />
      </footer>

    </div>

    ):(
      <Navigate to="/login" />
    )
  );
}

export default AutreEtab;
