import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { ButtonDelete, ButtonEdit, ButtonContainer, Username,
  ThematiquesContainer, CardImage, CardContainer, UsernameContainer,
  CardContent, PublicationTitle, PublicationInfos
} from "../styles/CartePublication.style";

import { AuthContext } from "../helpers/AuthContext";
import ThematiqueIcon from "./ThematiqueIcon";
import Thematiques from "./Thematiques";
import EditPubPopup from "./EditPubPopup";
import ConfirmationPopup from "./ConfirmationPopup";


const CartePublication = ({id, url, title, link, fallbackUrl, imageUrl, reload, idChercheur}) => {

  const { authState } = useContext(AuthContext);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [publicationSousThematiques, setPublicationSousThematiques] = useState([]);
  const [publicationChercheur, setPublicationChercheur] = useState([]);
  const [allThematique, setAllThematique] = useState([]);
  const [extractedThematiques, setExtractedThematiques] = useState([]);
  const [image, setImage] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editedPublication, setEditedPublication] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const formattedUrl =
    url && (url.startsWith("http://") || url.startsWith("https://"))
      ? url
      : `http://${url}`;

  const handleEditEvent = (publication) => {
    setIsEditPopupOpen(true);
    setEditedPublication(publication);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupOpen(false);
    setEditedPublication(null);
    reload();
    setReloadPage(!reloadPage);
  };

  const handleDeleteEvent = async (id) => {
    try {
      await axios.delete(`https://back.r3mob.fr/createPublication/${id}`);
      reload();
      // Gérez la réponse ici
    } catch (error) {
      console.error(error);
      // Gérez les erreurs ici
    }
  };

  useEffect(() => {
    setPlayAnimation(true);
    return () => {
      setPlayAnimation(false);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`https://back.r3mob.fr/publication/thematiques/${id}`)
      .then((res) => {
        if (res.data.error) {
          // console.log(res.data.error);
        } else {
          setPublicationSousThematiques(res.data);
        }
      });
    axios
      .get(`https://back.r3mob.fr/publication/chercheur/${id}`)
      .then((res) => {
        if (res.data.error) {
          // console.log(res.data.error);
        } else {
          setPublicationChercheur(res.data);
        }
      });
    axios
      .get(`https://back.r3mob.fr/publication/image/${imageUrl}`)
      .then((res) => {
        if (res.data && res.data !== null) {
          // Vérifiez que res.data n'est pas nul
          setImage(`data:image/png;base64,${res.data}`);
        } else {
          setImage(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, imageUrl,reloadPage]);

  useEffect(() => {
    axios.get(`https://back.r3mob.fr/thematique`).then((res) => {
      if (res.data.error) {
        // console.log(res.data.error);
      } else {
        setAllThematique(res.data);
      }
    });
  }, []);

  useEffect(() => {
    // Define an async function to fetch the thematic data
    const fetchThematiqueData = async () => {
      const thematicData = [];
      for (const publication of publicationSousThematiques) {
        if (publication.SousThematique) {
        let thematicId = publication.SousThematique.ThematiqueId;
        let thematic = allThematique.find(
          (thematique) => thematique.id === thematicId
        );
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex(
            (data) => data.thematique.nom === thematic.nom
          );
          if (thematiqueIndex !== -1) {
            // If the thematique already exists, update the sousThematique field
            thematicData[thematiqueIndex].sousThematique.push(
              " , ",
              publication.SousThematique.nom
            );
          } else {
            // If the thematique doesn't exist, add it to the array
            const them = Thematiques.find(
              (thematique) => thematique.nom === thematic.nom
            );
            thematicData.push({
              thematique: them,
              sousThematique: [publication.SousThematique.nom],
            });
          }
        }
        }
        if (publication.ThematiqueId) {
         let thematicId = publication.ThematiqueId;
        if(!thematicId) continue;
         let thematic = allThematique.find((thematique) => thematique.id === thematicId);
        if (thematic) {
          const thematiqueIndex = thematicData.findIndex((data) => data.thematique.nom === thematic.nom);
          // If the thematique doesn't exist, add it to the array
          if (thematiqueIndex === -1) {
            const them = Thematiques.find((thematique) => thematique.nom === thematic.nom);
            thematicData.push({ thematique: them, sousThematique: [them.nom] });
          }
        }
      }
      }
      setExtractedThematiques(thematicData);
    };
    fetchThematiqueData();
  }, [publicationSousThematiques, allThematique]);

  const handleShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Appeler la fonction de suppression réelle ici
      await axios.delete(`https://back.r3mob.fr/createPublication/${id}`);
      reload();
      // Masquer la popup de confirmation
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error(error);
      // Gérez les erreurs ici
      // Masquer la popup de confirmation en cas d'erreur si nécessaire
      setShowDeleteConfirmation(false);
    }
  };

  const backgroundImage = image ? `${image}` : `${fallbackUrl}`;


  return (

    <>

      {authState.status && authState.username === idChercheur && (
        <ButtonContainer>
          <ButtonEdit onClick={() => handleEditEvent(id)}>
            <FontAwesomeIcon icon={faEdit} />
          </ButtonEdit>
          <ButtonDelete onClick={() => handleShowDeleteConfirmation()}>
            <FontAwesomeIcon icon={faTrash} />
          </ButtonDelete>
        </ButtonContainer>
      )}

      <a
        href={url && url !== "" ? formattedUrl : undefined}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >

        <CardContainer playAnimation={playAnimation}>

          <CardImage src={backgroundImage} />

          <CardContent>

            <PublicationTitle>{title}</PublicationTitle>

            <PublicationInfos>

              <UsernameContainer>
                {publicationChercheur.map((publication) => (
                  <Username
                    key={publication.Personnel.id}
                    href={
                      authState.status
                        ? `chercheur/${publication.Personnel.id}`
                        : undefined
                    }
                  >
                    {publication.Personnel.username}
                  </Username>
                ))}
              </UsernameContainer>

              <ThematiquesContainer>
                {extractedThematiques.map((publicationSousThematiques) => (
                  publicationSousThematiques.thematique && (
                  <ThematiqueIcon
                    key={publicationSousThematiques.thematique.nom}
                    icon={
                      "../thematiques/" +
                      publicationSousThematiques.thematique.icon
                    }
                    backgroundColor={
                      publicationSousThematiques.thematique.backgroundColor
                    }
                    subThematiques={publicationSousThematiques.sousThematique}
                  />
                )
                ))}
              </ThematiquesContainer>

            </PublicationInfos>
          </CardContent>

        </CardContainer>

      </a>

      {isEditPopupOpen && (
        <EditPubPopup
          pubId={id}
          title={title}
          pubUrl={formattedUrl}
          publication={editedPublication}
          onSave={() => {
            handleCloseEditPopup();
          }}
          onClose={handleCloseEditPopup}
          image={backgroundImage}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationPopup
          onClose={() => setShowDeleteConfirmation(false)}
          onSave={handleConfirmDelete}
        />
      )}

    </>
  );
};


export default CartePublication;
